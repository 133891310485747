<template>
  <div>
    <h4>Documents</h4>
    <div class="mt-3 page-content">
      <!-- Search Filter -->
      <form @submit.prevent="search">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Employee Name</label>
            <input
              type="text"
              placeholder="Search Name Here"
              class="form-control"
              v-model="employeeName"
            />
          </div>

          <div class="form-group ml-3"></div>

          <div class="form-group col-md-3">
            <label>Organisation (Filter)</label>
            <select class="form-control" v-model="organisationId">
              <option value>All</option>

              <option
                v-for="org in organisations"
                :value="org.Id"
                :key="org.Id"
              >
                {{ org.Name }}
              </option>
            </select>
          </div>

          <div class="form-group ml-3"></div>

          <div class="form-group col-md-1">
            <button
              type="submit"
              class="btn btn-primary form-control"
              style="margin-top: 1.65rem"
            >
              Go
            </button>
          </div>
        </div>
      </form>
      <!-- /Search Filter -->

      <!-- Add Document Link -->
      <button
        class="btn btn-sm btn-link pl-0"
        @click="$modal.show('addDocumentModal')"
      >
        + Add Document
      </button>
      <!-- /Add Document Link -->

      <!-- Table Result -->
      <document-table
        :token="token"
        :isFromDocumentsMenu="true"
        :showDelete="true"
        :employeeName="employeeName"
        :organisationId="organisationId"
        :key="counter"
      ></document-table>
      <!-- /Table Result -->
    </div>

    <!-- Add Document Modal -->
    <modal
      name="addDocumentModal"
      width="50%"
      height="auto"
      :scrollable="true"
      :clickToClose="false"
    >
      <add-document @refreshData="refreshData"></add-document>
    </modal>
    <!-- /Add Document Modal -->
  </div>
</template>

<script>
import API from "../../../http-common.js";
import DocumentTable from "../../Shared/DocumentTable.vue";
import AddDocument from "./Create.vue";
export default {
  components: {
    DocumentTable,
    AddDocument,
  },
  data() {
    return {
      //for filter
      employeeName: "",
      organisationId: "",
      organisations: [],
      //for table
      counter: 0,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  methods: {
    refreshData() {
      this.employeeName = "";
      this.organisationId = "";
      this.counter++;
    },
    search() {
      //set the state from filter
      this.$store.state.documentFilter.employeeName = this.employeeName;
      this.$store.state.documentFilter.organisationId = this.organisationId;
      this.counter++;
    },
    getOrganisationListForDropdown() {
      API.admin
        .getOrganisationListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.organisations = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.getOrganisationListForDropdown();
    //populate the filter from state
    let docFilter = this.$store.state.documentFilter;
    this.employeeName = docFilter.employeeName;
    this.organisationId = docFilter.organisationId;
    this.counter++;
  },
};
</script>