<template>
  <div>
    <div class="text-center my-3">
      <h4>Add Document</h4>
    </div>
    <form @submit.prevent="addDocument" class="page-content mx-2 my-2 pb-0">
      <!-- Organisation -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label"
          >Organisation<span class="text-danger">*</span>
        </label>
        <label class="col-form-label">:</label>
        <div class="col-md-7">
          <select v-model="organisationId" class="form-control" required>
            <option value selected>-- Select Organisation --</option>
            <option v-for="org in organisations" :value="org.Id" :key="org.Id">
              {{ org.Name }}
            </option>
          </select>
        </div>
      </div>
      <!-- /Organisation -->

      <!-- Employee -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label"
          >Employee<span class="text-danger">*</span>
        </label>
        <label class="col-form-label">:</label>
        <div class="col-md-7">
          <select v-model="employeeId" class="form-control" required>
            <option value selected>-- Select Employee --</option>
            <option v-for="emp in employees" :value="emp.Id" :key="emp.Id">
              {{ emp.Name }}
            </option>
          </select>
        </div>
      </div>
      <!-- /Employee -->

      <!-- Document -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label"
          >Document<span class="text-danger">*</span>
          <i
            class="fa fa-exclamation-circle ml-1"
            title="Maximum file size: 5 MB. Only zip, pdf, jpg, jpeg, png, doc, docx, xls, xlsx are allowed"
          ></i>
        </label>
        <label class="col-form-label">:</label>
        <div class="col-md-7">
          <input
            type="file"
            class="custom-file-input"
            accept=".zip,.pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx"
            id="docFileUpload"
            ref="docFileUpload"
            @change="onDocSelected($event)"
            required
          />
          <label class="custom-file-label" for="docFileUpload">{{
            docFileName
          }}</label>
        </div>
        <div class="col-md-1 mt-2 pl-md-0">
          <a
            v-if="docFile"
            href="javascript:void(0)"
            id="removeDocFile"
            title="Remove Document"
            @click.prevent="removeDocFile"
            ><i class="fa fa-trash-alt" style="color: grey"></i
          ></a>
        </div>
      </div>
      <!-- /Document -->

      <!-- Remarks -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label"
          >Remarks<span class="text-danger">*</span>
        </label>
        <label class="col-form-label">:</label>
        <div class="col-md-7">
          <textarea class="form-control" v-model="remarks" required> </textarea>
        </div>
      </div>
      <!-- /Remarks -->

      <!-- Employer's Email -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label"
          >Employer's Email<span class="text-danger">*</span>
        </label>
        <label class="col-form-label">:</label>
        <div class="col-md-7">
          <input
            type="email"
            v-model="sentTo"
            class="form-control"
            required
            placeholder="Enter Employer Email Address Here"
          />
        </div>
      </div>
      <!-- /Employer's Email -->

      <hr class="my-0" />
      <div class="form-group row mt-3 mb-0">
        <div class="col-md-12 text-right">
          <button type="submit" class="btn btn-primary mb-3 mr-3">
            Upload
          </button>
          <button
            type="button"
            class="btn btn-secondary mb-3"
            @click="$modal.hide('addDocumentModal')"
          >
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      //for filter
      organisations: [],
      employees: [],
      //for form
      organisationId: "",
      employeeId: "",
      docFileName: "Upload Document",
      docFile: null,
      remarks: "",
      sentTo: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  watch: {
    organisationId(newVal) {
      this.employeeId = "";
      if (!newVal) {
        this.employees = [];
      } else {
        this.getEmployeesByOrganisationId();
      }
    },
  },
  methods: {
    addDocument() {
      let param = {
        Token: this.token,
        UserId: this.employeeId,
        DocFile: this.docFile,
        DocFileName: this.docFileName,
        Remarks: this.remarks,
        SentTo: this.sentTo,
        EnableNotification: false,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to upload the document?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          this.$store.state.isLoading = true;
          API.employee
            .addDocument(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  {
                    icon: "error",
                  }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }
              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.$emit("refreshData");
                this.$modal.hide("addDocumentModal");
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getOrganisationListForDropdown() {
      API.admin
        .getOrganisationListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.organisations = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getEmployeesByOrganisationId() {
      API.admin
        .getEmployeesByOrganisationId({
          Token: this.token,
          Id: this.organisationId,
        })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }
          this.employees = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for document upload control
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    onDocSelected(event) {
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > 5 * 1024 * 1024) {
        this.$swal("File size exceeds 5 MB", { icon: "error" });
        return;
      }
      this.docFileName = file.name;
      let self = this;
      let promise = self.getBase64(file);
      promise.then(function (result) {
        self.docFile = result;
      });
    },
    removeDocFile() {
      this.docFile = null;
      this.docFileName = "Upload Document";
      this.$refs.docFileUpload.value = null;
    },
  },
  created() {
    this.getOrganisationListForDropdown();
  },
};
</script>

<style scoped>
.col-form-label {
  max-width: 205px;
}
.custom-file-label {
  margin-left: 15px;
  width: 407px;
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .custom-file-label {
    width: 430px;
  }
}
</style>