<template>
  <div id="app">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="true"
    ></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "App",
  components: {
    Loading,
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>

<style>
</style>
