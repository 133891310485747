<template>
  <div>
    <h4>Employees</h4>
    <div class="mt-3 page-content">
      <form @submit.prevent="search">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Employee Name</label>
            <input
              type="text"
              placeholder="Search Name Here"
              class="form-control"
              v-model="name"
            />
          </div>

          <div class="form-group ml-3"></div>

          <div class="form-group col-md-4">
            <label>Organisation (Filter)</label>
            <select class="form-control" v-model="organisationId">
              <option value>All</option>

              <option
                v-for="org in organisations"
                :value="org.Id"
                :key="org.Id"
              >
                {{ org.Name }}
              </option>
            </select>
          </div>

          <div class="form-group ml-3"></div>
          <div class="form-group col-md-2">
            <label>Status (Filter)</label>
            <select class="form-control" v-model="active">
              <option value="" selected>All</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div class="form-group ml-3"></div>
          <div class="form-group col-md-1">
            <button
              type="submit"
              class="btn btn-primary form-control"
              style="margin-top: 1.65rem"
            >
              Go
            </button>
          </div>
        </div>
      </form>

      <router-link :to="{ name: 'adminEmployeeCreate' }"
        >+ Add Employee</router-link
      >

      <div class="table-responsive" v-if="employees.length">
        <table class="table table-bordered mt-3" style="width: 100%">
          <thead class="thead-light">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Organisation Name</th>
              <th>Contact Number</th>
              <th>Leave Entitlement Organisation</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="emp in employees" :key="emp.Id">
              <td class="align-middle">{{ emp.EmployeeId }}</td>
              <td class="align-middle">{{ emp.Name }}</td>
              <td class="align-middle">{{ emp.OrganisationName }}</td>
              <td class="align-middle">{{ emp.ContactNumber }}</td>
              <td class="align-middle">{{ emp.LeaveEntitlementType }}</td>
              <td class="align-middle">
                {{ emp.Active ? "Active" : "Inactive" }}
              </td>
              <td class="align-middle">
                <router-link
                  :to="{
                    name: 'adminEmployeeShow',
                    params: { id: emp.Id },
                  }"
                  >View</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <router-link
                  :to="{
                    name: 'adminEmployeeEdit',
                    params: { id: emp.Id },
                  }"
                  >Edit</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <a
                  href="javascript:void(0)"
                  @click.prevent="deleteEmployee(emp.Id)"
                  >Delete</a
                >
                <span class="ml-1 mr-1">|</span>
                <router-link
                  :to="{
                    name: 'adminEmployeeLeaveEntitlement',
                    params: { id: emp.Id },
                  }"
                  >View Leave Entitlement</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <router-link
                  :to="{
                    name: 'adminEmployeeDocument',
                    params: { id: emp.Id },
                  }"
                  >View Documents</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="employees.length" class="display-page">
        Displaying
        <select
          class="custom-select custom-select-sm"
          v-model="paging.SizePerPage"
          style="width: 65px; margin-right: 10px; margin-left: 5px"
        >
          <option
            v-for="item in paging.Sizes"
            v-bind:key="item"
            v-bind:value="item"
          >
            {{ item }}
          </option>
        </select>
        <button
          v-if="paging.CurrentPage > 1"
          v-on:click="first()"
          class="btn btn-sm btn-link"
        >
          First
        </button>
        <button
          v-on:click="prev()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage > 1"
        >
          Prev
        </button>
        <span style="padding-left: 5px; padding-right: 5px"
          >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
        >
        <button
          v-on:click="next()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage < paging.TotalPage"
        >
          Next
        </button>
        <button
          v-if="paging.CurrentPage < paging.TotalPage"
          v-on:click="last()"
          class="btn btn-sm btn-link"
        >
          Last
        </button>
      </div>
      <div v-if="!employees.length" class="text-center">
        No records available
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      //for filter
      name: "",
      organisationId: "",
      active: "",
      //for table
      employees: [],
      organisations: [],
      //for paging
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getEmployeeList();
    },
  },
  methods: {
    first() {
      this.paging.CurrentPage = 1;
      this.getEmployeeList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getEmployeeList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getEmployeeList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getEmployeeList();
    },
    search() {
      //set the state from filter
      this.$store.state.employeeFilter.employeeName = this.name;
      this.$store.state.employeeFilter.organisationId = this.organisationId;
      this.$store.state.employeeFilter.status = this.active;
      this.paging.CurrentPage = 1;
      this.getEmployeeList();
    },
    deleteEmployee(empId) {
      let param = {
        Token: this.token,
        Id: empId,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the employee?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.state.isLoading = true;
          API.admin
            .deleteEmployee(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.paging.CurrentPage = 1;
                this.getEmployeeList();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getEmployeeList() {
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        Name: this.name,
        OrganisationId: this.organisationId,
        Active: this.active,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getEmployeeList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.employees = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getOrganisationListForDropdown() {
      API.admin
        .getOrganisationListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.organisations = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    //populate the filter from state
    let employeeFilter = this.$store.state.employeeFilter;
    this.name = employeeFilter.employeeName;
    this.organisationId = employeeFilter.organisationId;
    this.active = employeeFilter.status;
    this.getEmployeeList();
    this.getOrganisationListForDropdown();
  },
};
</script>
