<template>
  <div>
    <h4>Add Employee</h4>
    <form class="mt-4 mb-5 page-content" @submit.prevent="addEmployee">
      <!-- DETAILS -->
      <div class="form-group mb-2">
        <h5>Details</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label>Organisation<span class="text-danger">*</span></label>
        <select v-model="organisationId" class="form-control" required>
          <option value selected>Select Organisation</option>
          <option v-for="org in organisations" :value="org.Id" :key="org.Id">
            {{ org.Name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>ID<span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="employeeId"
          class="form-control"
          placeholder="Input ID Here"
          required
          maxlength="50"
        />
      </div>
      <div class="form-group">
        <label
          >Password
          <span style="font-size: 0.7rem"
            ><i
              >(must be minimum of 6 characters with combination of upper and
              lower case letters, numbers, and non-alphanumeric symbols)</i
            ></span
          ></label
        >
        <input
          type="text"
          v-model="password"
          class="form-control"
          placeholder="Password"
          maxlength="20"
        />
      </div>
      <div class="form-group">
        <label>Name<span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="name"
          class="form-control"
          placeholder="Input Name Here"
          required
          maxlength="50"
        />
      </div>
      <div class="form-group">
        <label>Date of Birth</label>
        <input
          type="date"
          v-model="birthDate"
          class="form-control"
          placeholder="Input Date of Birth Here"
        />
      </div>
      <div class="form-group">
        <label>NRIC Number</label>
        <input
          type="text"
          v-model="nric"
          class="form-control"
          placeholder="Input NRIC Here"
          maxlength="50"
        />
      </div>
      <div class="form-group">
        <label>Gender</label>
        <input
          type="text"
          v-model="gender"
          class="form-control"
          placeholder="Input Gender Here"
          maxlength="50"
        />
      </div>
      <div class="form-group">
        <label>Place of Birth</label>
        <input
          type="text"
          v-model="placeOfBirth"
          class="form-control"
          placeholder="Input Place of Birth Here"
          maxlength="100"
        />
      </div>
      <div class="form-group">
        <label>Nationality</label>
        <input
          type="text"
          v-model="nationality"
          class="form-control"
          placeholder="Input Nationality Here"
          maxlength="100"
        />
      </div>
      <div class="form-group">
        <label>Citizenship</label>
        <select v-model="citizenshipId" class="form-control">
          <option value selected>Select Citizenship</option>
          <option v-for="item in citizenships" :value="item.Id" :key="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
      <div class="form-group" v-if="selectedCitizenship == 'SPR'">
        <label>PR Issuance Date</label>
        <input
          type="date"
          v-model="prIssuanceDate"
          class="form-control"
          placeholder="Input PR Issuance Date Here"
        />
      </div>
      <div class="form-group">
        <label>Religion</label>
        <input
          type="text"
          v-model="religion"
          class="form-control"
          placeholder="Input Religion Here"
          maxlength="50"
        />
      </div>
      <div class="form-group">
        <label>Marital Status<span class="text-danger">*</span></label>
        <div style="margin-top: -0.4rem">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="marital_status"
              value="true"
              v-model="maritalStatus"
              required
              id="maritalStatusYes"
            />
            <label class="form-check-label" for="maritalStatusYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="marital_status"
              value="false"
              v-model="maritalStatus"
              required
              id="maritalStatusNo"
            />
            <label class="form-check-label" for="maritalStatusNo">No</label>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="maritalStatus == 'true'">
        <label>Number of Children</label>
        <input
          type="number"
          min="0"
          v-model="numberOfChildren"
          class="form-control"
          placeholder="Input Number of Children Here"
        />
      </div>
      <!-- /DETAILS -->

      <!-- CONTACT INFORMATION -->
      <div class="form-group mt-4 mb-2">
        <h5>Contact Information</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label>Email<span class="text-danger">*</span></label>
        <input
          type="email"
          v-model="email"
          class="form-control"
          placeholder="Input Email Here"
          required
          maxlength="100"
        />
      </div>
      <div class="form-group">
        <label>Contact Number</label>
        <input
          type="text"
          v-model="contactNumber"
          class="form-control"
          placeholder="Input Contact Number Here"
          maxlength="50"
        />
      </div>
      <!-- Address -->
      <div class="form-group mb-0">
        <label
          ><i
            class="fas fa-plus-circle mr-1"
            title="Add Address"
            @click="addAddress"
          ></i
          >Address</label
        >
      </div>
      <div class="form-group ml-4 mb-2" v-for="(item, i) in addresses" :key="i">
        <label
          ><i
            class="fas fa-minus-circle mr-1"
            title="Remove Address"
            @click="removeAddress(i)"
          ></i
          >Address {{ i + 1 }}</label
        >
        <input
          type="text"
          v-model="item.name"
          class="form-control"
          :placeholder="'Input Address ' + (i + 1) + ' Here'"
          maxlength="200"
        />
      </div>
      <!-- /Address -->
      <!-- /CONTACT INFORMATION -->

      <!-- EMPLOYMENT INFORMATION -->
      <div class="form-group mt-4 mb-2">
        <h5>Employment Information</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label>Company Join Date<span class="text-danger">*</span></label>
        <input
          type="date"
          v-model="companyJoinDate"
          class="form-control"
          placeholder="Input Company Join Date Here"
          required
        />
      </div>
      <div class="form-group">
        <label>Probational Period</label>
        <input
          type="text"
          v-model="probationalPeriod"
          class="form-control"
          placeholder="Input Probational Period Here"
          maxlength="100"
        />
      </div>
      <div class="form-group">
        <label>Department</label>
        <input
          type="text"
          v-model="department"
          class="form-control"
          placeholder="Input Department Here"
        />
      </div>
      <div class="form-group">
        <label>Occupation</label>
        <input
          type="text"
          v-model="occupation"
          class="form-control"
          placeholder="Input Occupation Here"
          maxlength="100"
        />
      </div>
      <div class="form-group">
        <label>Confirmation Date</label>
        <input
          type="date"
          v-model="confirmationDate"
          class="form-control"
          placeholder="Input Confirmation Date Here"
        />
      </div>
      <div class="form-group">
        <label>Resignation Date</label>
        <input
          type="date"
          v-model="resignationDate"
          class="form-control"
          placeholder="Input Resignation Date Here"
        />
      </div>
      <!-- /EMPLOYMENT INFORMATION -->

      <!-- PAYROLL INFORMATION -->
      <div class="form-group mt-4 mb-2">
        <h5>Payroll Information</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label>Basic Salary</label>
        <input
          type="number"
          min="0"
          v-model="basicSalary"
          class="form-control"
          placeholder="Input Basic Salary Here"
          step="0.01"
        />
      </div>
      <div class="form-group">
        <label>Employee CPF (%)</label>
        <input
          type="number"
          min="0"
          v-model="employeeCpf"
          class="form-control"
          placeholder="Input Employee CPF Here"
          step="0.01"
        />
      </div>
      <div class="form-group">
        <label>Employer CPF (%)</label>
        <input
          type="number"
          min="0"
          v-model="employerCpf"
          class="form-control"
          placeholder="Input Employer CPF Here"
          step="0.01"
        />
      </div>
      <div class="form-group">
        <label>Salary Effective Date</label>
        <input
          type="date"
          v-model="salaryEffectiveDate"
          class="form-control"
          placeholder="Input Salary Effective Date Here"
        />
      </div>
      <!-- Bank -->
      <div class="form-group mb-0 mt-4">
        <label
          ><i
            class="fas fa-plus-circle mr-1"
            title="Add Bank Details"
            @click="addBank()"
          ></i
          >Bank Details</label
        >
      </div>
      <div class="mb-3">
        <div class="form-group ml-4 mb-2" v-for="(item, i) in banks" :key="i">
          <label class="mb-1"
            ><i
              class="fas fa-minus-circle mr-1"
              title="Remove Bank Details"
              @click="removeBank(i)"
            ></i
            >Bank Account {{ i + 1
            }}<span class="text-danger" v-if="i == 0">*</span></label
          >
          <div class="ml-4">
            <label class="mb-1">Bank Name {{ i + 1 }}</label>
            <input
              type="text"
              v-model="item.name"
              class="form-control"
              :placeholder="'Input Bank Name ' + (i + 1) + ' Here'"
              maxlength="100"
              :required="i == 0"
            />
            <label class="mb-1 mt-2">Bank Account Number {{ i + 1 }}</label>
            <input
              type="number"
              v-model="item.accountNo"
              class="form-control"
              :placeholder="'Input Bank Account Number ' + (i + 1) + ' Here'"
              :required="i == 0"
            />
          </div>
        </div>
      </div>
      <!-- /Bank -->

      <!-- Allowance -->
      <div class="form-group mb-0 mt-4">
        <label
          ><i
            class="fas fa-plus-circle mr-1"
            title="Add Allowance"
            @click="addAllowance()"
          ></i
          >Allowance</label
        >
      </div>
      <div class="mb-3">
        <div
          class="form-group ml-4 mb-2"
          v-for="(item, i) in allowances"
          :key="i"
        >
          <label class="mb-1"
            ><i
              class="fas fa-minus-circle mr-1"
              title="Remove Allowance"
              @click="removeAllowance(i)"
            ></i
            >Allowance {{ i + 1 }}</label
          >
          <div class="ml-4">
            <label class="mb-1">Allowance Name {{ i + 1 }}</label>
            <input
              type="text"
              v-model="item.name"
              class="form-control"
              :placeholder="'Input Allowance Name ' + (i + 1) + ' Here'"
              maxlength="100"
            />
            <label class="mb-1 mt-2">Allowance Amount {{ i + 1 }}</label>
            <input
              type="number"
              v-model="item.amount"
              class="form-control"
              :placeholder="'Input Allowance Amount ' + (i + 1) + ' Here'"
              step="0.01"
            />
          </div>
        </div>
      </div>
      <!-- /Allowance -->

      <!-- Deduction -->
      <div class="form-group mb-0 mt-4">
        <label
          ><i
            class="fas fa-plus-circle mr-1"
            title="Add Deduction"
            @click="addDeduction()"
          ></i
          >Deduction</label
        >
      </div>
      <div class="mb-3">
        <div
          class="form-group ml-4 mb-2"
          v-for="(item, i) in deductions"
          :key="i"
        >
          <label class="mb-1"
            ><i
              class="fas fa-minus-circle mr-1"
              title="Remove Deduction"
              @click="removeDeduction(i)"
            ></i
            >Deduction {{ i + 1 }}</label
          >
          <div class="ml-4">
            <label class="mb-1">Deduction Name {{ i + 1 }}</label>
            <input
              type="text"
              v-model="item.name"
              class="form-control"
              :placeholder="'Input Deduction Name ' + (i + 1) + ' Here'"
              maxlength="100"
            />
            <label class="mb-1 mt-2">Deduction Amount {{ i + 1 }}</label>
            <input
              type="number"
              v-model="item.amount"
              class="form-control"
              :placeholder="'Input Deduction Amount ' + (i + 1) + ' Here'"
              step="0.01"
            />
          </div>
        </div>
      </div>
      <!-- /Deduction -->

      <div class="form-group mt-4">
        <label>Fund Contribution Type (CDAC, MOSQ, SINDA, EURA)</label>
        <input
          type="text"
          v-model="fundContributionType"
          class="form-control"
          placeholder="Input Fund Contribution Type Here"
        />
      </div>
      <div class="form-group">
        <label>Foreign Worker's Levy</label>
        <input
          type="number"
          min="0"
          v-model="foreignWorkerLevy"
          class="form-control"
          placeholder="Input Levy Amount Here"
          step="0.01"
        />
      </div>
      <!-- /PAYROLL INFORMATION -->

      <!-- LEAVE DETAILS -->
      <div class="form-group mt-4 mb-2">
        <h5>Leave Details</h5>
        <hr class="my-0" />
      </div>

      <div class="form-group">
        <label
          >Type of Leave Entitlement Organisation<span class="text-danger"
            >*</span
          ></label
        >
        <select
          v-model="leaveEntitlementTypeId"
          class="form-control"
          required
          id="leaveEntitlementDropDown"
        >
          <option value selected>
            Select Type of Leave Entitlement Organisation
          </option>

          <option
            v-for="item in leaveEntitlementTypes"
            :value="item.Id"
            :key="item.Id"
          >
            {{ item.Description }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label
          >Length of Service (Years)<span class="text-danger">*</span></label
        >
        <input
          type="number"
          min="0"
          v-model="serviceLength"
          class="form-control"
          placeholder="Input Length of Service (Years) Here"
          required
        />
      </div>
      <div class="form-group">
        <label>Remarks</label>
        <textarea
          v-model="remarks"
          type="text"
          placeholder="Input Remarks Here"
          class="form-control"
        >
        </textarea>
      </div>
      <!-- /LEAVE DETAILS -->

      <div class="text-center mt-3">
        <button class="btn btn-primary mr-3" type="submit">Add</button>
        <router-link class="btn btn-danger" :to="{ name: 'adminEmployeeIndex' }"
          >Cancel</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      organisationId: "",
      employeeId: "",
      password: "",
      name: "",
      birthDate: "",
      email: "",
      contactNumber: "",
      nric: "",
      department: "",
      companyJoinDate: "",
      maritalStatus: null,
      numberOfChildren: null,
      leaveEntitlementTypeId: "",
      serviceLength: null,
      organisations: [],
      leaveEntitlementTypes: [],

      gender: "",
      placeOfBirth: "",
      nationality: "",
      citizenshipId: "",
      citizenships: [],
      prIssuanceDate: "",
      religion: "",
      addresses: [{ name: "" }, { name: "" }],
      probationalPeriod: "",
      occupation: "",
      confirmationDate: "",
      resignationDate: "",
      basicSalary: null,
      employeeCpf: null,
      employerCpf: null,
      salaryEffectiveDate: "",
      banks: [
        { name: "", accountNo: "" },
        { name: "", accountNo: "" },
      ],
      allowances: [
        { name: "", amount: null },
        { name: "", amount: null },
      ],
      deductions: [
        { name: "", amount: null },
        { name: "", amount: null },
      ],
      fundContributionType: "",
      foreignWorkerLevy: null,
      remarks: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
    selectedCitizenship() {
      let citizenship = this.citizenships.find(
        (c) => c.Id == this.citizenshipId
      );
      return citizenship != null ? citizenship.Name : "";
    },
  },
  watch: {
    selectedCitizenship(val) {
      if (val == "Singaporean" || val == "SPR") {
        this.employeeCpf = 20;
        this.employerCpf = 17;
      } else {
        this.employeeCpf = null;
        this.employerCpf = null;
      }
    },
  },
  methods: {
    addEmployee() {
      //must have at least 1 bank account
      if (this.banks.length == 0) {
        this.$swal("Please add at least 1 bank account", { icon: "error" });
        return;
      }
      let param = {
        Token: this.token,
        OrganisationId: this.organisationId,
        EmployeeId: this.employeeId,
        Password: this.password,
        Name: this.name,
        BirthDateFormStr: this.birthDate,
        Email: this.email,
        ContactNumber: this.contactNumber,
        NRIC: this.nric,
        Department: this.department,
        CompanyJoinDateFormStr: this.companyJoinDate,
        MaritalStatus: this.maritalStatus,
        NumberOfChildren: this.numberOfChildren,
        LeaveEntitlementTypeId: this.leaveEntitlementTypeId,
        ServiceLength: this.serviceLength,
        Gender: this.gender,
        PlaceOfBirth: this.placeOfBirth,
        Nationality: this.nationality,
        CitizenshipId: this.citizenshipId,
        PR_IssuanceDateFormStr: this.prIssuanceDate,
        Religion: this.religion,
        ProbationalPeriod: this.probationalPeriod,
        Occupation: this.occupation,
        ConfirmationDateFormStr: this.confirmationDate,
        ResignationDateFormStr: this.resignationDate,
        BasicSalary: this.basicSalary,
        EmployeeCpf: this.employeeCpf,
        EmployerCpf: this.employerCpf,
        SalaryEffectiveDateFormStr: this.salaryEffectiveDate,
        FundContributionType: this.fundContributionType,
        ForeignWorkerLevy: this.foreignWorkerLevy,
        Remarks: this.remarks,
        Addresses: [],
        Banks: [],
        Allowances: [],
        Deductions: [],
      };
      for (let item of this.addresses) {
        param.Addresses.push({ Address: item.name });
      }
      for (let item of this.banks) {
        param.Banks.push({ Name: item.name, AccountNo: item.accountNo });
      }
      for (let item of this.allowances) {
        param.Allowances.push({ Name: item.name, Amount: item.amount });
      }
      for (let item of this.deductions) {
        param.Deductions.push({ Name: item.name, Amount: item.amount });
      }
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to add the employee?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          API.admin
            .addEmployee(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success)
                this.$router.replace({ name: "adminEmployeeIndex" });
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    //for dropdown
    getLeaveEntitlementTypes() {
      API.common
        .getLeaveEntitlementTypes({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.leaveEntitlementTypes = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getOrganisationListForDropdown() {
      API.admin
        .getOrganisationListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.organisations = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getCitizenshipListForDropdown() {
      API.common
        .getCitizenshipListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.citizenships = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for address, bank, allowance, deduction
    addAddress() {
      this.addresses.push({ name: "" });
    },
    removeAddress(index) {
      this.addresses.splice(index, 1);
    },
    addBank() {
      this.banks.push({ name: "", accountNo: "" });
    },
    removeBank(index) {
      this.banks.splice(index, 1);
    },
    addAllowance() {
      this.allowances.push({ name: "", amount: null });
    },
    removeAllowance(index) {
      this.allowances.splice(index, 1);
    },
    addDeduction() {
      this.deductions.push({ name: "", amount: null });
    },
    removeDeduction(index) {
      this.deductions.splice(index, 1);
    },
  },
  created() {
    this.getLeaveEntitlementTypes();
    this.getOrganisationListForDropdown();
    this.getCitizenshipListForDropdown();
  },
};
</script>

<style scoped>
input[type="radio"],
.form-check-label {
  cursor: pointer;
}
h5 {
  font-weight: 540;
}
.fas {
  cursor: pointer;
  font-size: 18px;
  vertical-align: middle;
}

.fa-plus-circle {
  color: darkgreen;
}

.fa-minus-circle {
  color: darkred;
}
</style>