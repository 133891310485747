<template>
  <div class="page-content-emp">
    <h4>Change Password</h4>
    <hr class="my-0" />
    <form @submit.prevent="submit" class="mt-4">
      <div class="form-group row">
        <label class="col-2 col-form-label">Current Password</label>
        <div class="col-10">
          <input
            v-model="currentPassword"
            type="password"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="col-12 alert alert-info text-justify" role="alert">
        Password Requirement: Must be minimum of 6 characters with combination
        of upper and lower case letters, numbers, and non-alphanumeric symbols
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label">New Password</label>
        <div class="col-10">
          <input
            v-model="newPassword"
            type="password"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2 col-form-label">Confirm New Password</label>
        <div class="col-10">
          <input
            v-model="confirmNewPassword"
            type="password"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-12 text-right">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  computed: {
    token() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      return data.Token;
    },
    userId() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      return data.Id;
    },
  },
  methods: {
    submit() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.$swal("New Password and Confirm New Password do not match", {
          icon: "error",
        });
        return;
      }
      let param = {
        Token: this.token,
        Id: this.userId,
        CurrentPassword: this.currentPassword,
        NewPassword: this.newPassword,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to change your password?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          API.employee
            .changePassword(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "login" });
                return;
              }
              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.currentPassword = "";
                this.newPassword = "";
                this.confirmNewPassword = "";
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.alert {
  width: 760px;
  padding: 5px;
  font-size: 0.7rem;
}
</style>