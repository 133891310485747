<template>
  <div class="page-content-emp mb-3">
    <h4>Employees' Leave Entitlements</h4>
    <hr class="my-0" />
    <!-- FILTER -->
    <div class="row mt-3">
      <div class="col-6">
        <label><b>Company</b></label
        ><br />
        {{ organisationName }}
      </div>
      <div class="col-6">
        <label><b>Leave Entitlement Organisation</b></label
        ><br />
        {{ leaveEntitlementOrganisationType }}
      </div>
    </div>
    <div class="row mt-3">
      <label class="ml-3 col-form-label" style="width: fit-content"
        ><b>Employee Name</b></label
      >
      <input
        type="text"
        placeholder="Search Name Here"
        class="form-control form-control-sm mx-3"
        style="width: 20rem"
        v-model="name"
      />
      <button class="btn btn-sm btn-primary" @click="view">View All</button>
    </div>
    <!-- LIST -->
    <div class="card mt-3" v-for="item in employees" :key="item.Id">
      <h6 class="card-header">
        <b>{{ item.Name }}</b>
      </h6>
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <label><b>Email</b></label
            ><br />{{ item.Email }}
          </div>
          <div class="col-3">
            <label><b>Length of Service (Years)</b></label
            ><br />{{ item.ServiceLength }}
          </div>
          <div class="col-3">
            <label><b>Leave Entitlement Type</b></label
            ><br />{{ item.LeaveEntitlementType }}
          </div>
          <div class="col-3 my-auto text-center">
            <button
              class="btn btn-sm btn-primary"
              @click="
                $router.push({
                  name: 'employeeLeaveEntitlementShow',
                  params: { id: item.Id },
                })
              "
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!employees.length" class="text-center mt-3">
      No records available
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      token: "",
      approverEmail: "",
      organisationName: "",
      leaveEntitlementOrganisationType: "",
      name: "",
      employees: [],
    };
  },
  computed: {
    approverUserId() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Id;
    },
  },
  methods: {
    view() {
      this.getEmployeeListByApproverEmail();
    },
    getEmployeeListByApproverEmail() {
      let param = {
        Token: this.token,
        ApproverUserId: this.approverUserId,
        ApproverEmail: this.approverEmail,
        Name: this.name,
      };
      this.$store.state.isLoading = true;
      API.employee
        .getEmployeeListByApproverEmail(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }
          if (response.data.success) {
            this.employees = response.data.data;
          } else {
            this.$swal(response.data.message, { icon: "error" });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    this.token = data.Token;
    this.approverEmail = data.Email;
    this.organisationName = data.OrganisationName;
    this.leaveEntitlementOrganisationType =
      data.LeaveEntitlementOrganisationType;

    this.getEmployeeListByApproverEmail();
  },
};
</script>