<template>
  <div class="page-content-emp">
    <select
      v-model="year"
      class="form-control"
      style="width: fit-content; font-weight: bold"
    >
      <option v-for="item in years" :value="item" :key="item">
        {{ item }}
      </option>
    </select>
    <div class="table-responsive mt-3">
      <table class="table table-bordered">
        <thead class="thead-light">
          <tr>
            <th style="width: 20%">Public Holiday</th>
            <th style="width: 15%">Date</th>
            <th style="width: 10%">Day</th>
            <th style="width: 35%">Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in displayedPublicHolidays" :key="item.Id">
            <td>{{ item.Name }}</td>
            <td>{{ item.DateStr }}</td>
            <td>{{ item.Day }}</td>
            <td>{{ item.Remarks }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      publicHolidays: [],
      year: "",
    };
  },
  computed: {
    token() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Token;
    },
    years() {
      let filteredYears = [...new Set(this.publicHolidays.map((c) => c.Year))];
      return filteredYears;
    },
    displayedPublicHolidays() {
      return this.publicHolidays.filter((c) => c.Year == this.year);
    },
  },
  methods: {
    getPublicHolidays() {
      this.$store.state.isLoading = true;
      API.admin
        .getPublicHolidayList({ Token: this.token })
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            let data = response.data.data;
            this.publicHolidays = data;
            this.year = data[data.length - 1].Year;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.getPublicHolidays();
  },
};
</script>