<template>
  <div>
    <h4>Change Password</h4>
    <form @submit.prevent="submit" class="mt-4 page-content">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Current Password</label>
        <div class="col-md-5">
          <input
            v-model="currentPassword"
            type="password"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="alert alert-info w-auto" role="alert">
            Password Requirement: Must be minimum of 6 characters with
            combination of upper and lower case letters, numbers, and
            non-alphanumeric symbols
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">New Password</label>
        <div class="col-md-5">
          <input
            v-model="newPassword"
            type="password"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Confirm New Password</label>
        <div class="col-md-5">
          <input
            v-model="confirmNewPassword"
            type="password"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-7 text-right">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
    userId() {
      return this.$store.state.userDetail.Id;
    },
  },
  methods: {
    submit() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.$swal("New Password and Confirm New Password do not match", {
          icon: "error",
        });
        return;
      }
      let param = {
        Token: this.token,
        Id: this.userId,
        CurrentPassword: this.currentPassword,
        NewPassword: this.newPassword,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to change your password?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          API.admin
            .changePassword(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.currentPassword = "";
                this.newPassword = "";
                this.confirmNewPassword = "";
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.alert {
  width: 650px;
  padding: 5px;
  font-size: 0.6rem;
}
</style>