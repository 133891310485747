<template>
  <div class="page-content-emp mb-3">
    <h4>{{ employeeName }}'s Leave Entitlements</h4>
    <hr class="my-0" />
    <div class="row mt-3">
      <div class="col-6">
        <label><b>Company</b></label
        ><br />
        {{ organisationName }}
      </div>
      <div class="col-6">
        <label><b>Leave Entitlement Organisation</b></label
        ><br />
        {{ leaveEntitlementOrganisationType }}
      </div>
    </div>
    <!-- FILTER -->
    <div class="row mt-3 align-items-center">
      <div class="col-md-1 mt-3 pr-0 fit-content">
        <b>Leave Type</b>
      </div>
      <div class="col-md-3 mt-md-3 fit-content">
        <select v-model="leaveTypeDesc" class="form-control form-control-sm">
          <option value selected>All Leave Types</option>
          <option
            v-for="(item, i) in leaveTypesDefault"
            :value="item.Description"
            :key="i"
          >
            {{ item.Description }}
          </option>
          <option value="Others" v-if="userLeaveTitles.length">Others</option>
        </select>
      </div>
      <template v-if="leaveTypeDesc == 'Others'">
        <div class="col-md-2 mt-3 pr-0 fit-content">
          <b>Title</b>
        </div>
        <div class="col-md-3 mt-md-3 fit-content">
          <select v-model="leaveTitle" class="form-control form-control-sm">
            <option value selected>All Titles</option>
            <option
              v-for="(title, i) in userLeaveTitles"
              :value="title.Description"
              :key="i"
            >
              {{ title.Description }}
            </option>
          </select>
        </div>
      </template>
      <div class="col-md-1 mt-3 pr-0 fit-content">
        <b>Year</b>
      </div>
      <div class="col-md-2 mt-md-3 fit-content">
        <select v-model="year" class="form-control form-control-sm">
          <option value selected>All</option>
          <option v-for="item in years" :value="item" :key="item">
            {{ item }}
          </option>
        </select>
      </div>
      <div class="col-md-1 mt-3">
        <button class="btn btn-sm btn-primary" @click="view">View All</button>
      </div>
    </div>
    <!-- /FILTER -->
    <!-- LEAVE ENTITLEMENT LIST -->
    <div class="card mt-3" v-for="item in leaveEntitlements" :key="item.Id">
      <h6 class="card-header">
        <b>{{ item.LeaveTypeDescription }}</b>
      </h6>
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <label><b>Effective From</b></label
            ><br />{{ item.EffectiveDateDisplay }}
          </div>
          <div class="col-3">
            <label><b>Effective Till</b></label
            ><br />{{ item.ExpiryDateDisplay }}
          </div>
          <div class="col-6">
            <label><b>Total Leave Entitlement Days</b></label
            ><br />{{ item.TotalLeaveEntitlementDays }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <label><b>Leave Days Taken</b></label
            ><br />{{ item.LeaveDaysTaken }}
          </div>
          <div class="col-6">
            <label><b>Leave Days Balance</b></label
            ><br />{{ item.LeaveDaysBalance }}
          </div>
        </div>
      </div>
    </div>
    <!-- Pagination -->
    <div v-if="leaveEntitlements.length" class="mt-3">
      Displaying
      <select
        class="custom-select custom-select-sm"
        v-model="paging.SizePerPage"
        style="width: 65px; margin-right: 10px; margin-left: 5px"
      >
        <option
          v-for="item in paging.Sizes"
          v-bind:key="item"
          v-bind:value="item"
        >
          {{ item }}
        </option>
      </select>
      <button
        v-if="paging.CurrentPage > 1"
        v-on:click="first()"
        class="btn btn-sm btn-link"
      >
        First
      </button>
      <button
        v-on:click="prev()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage > 1"
      >
        Prev
      </button>
      <span style="padding-left: 5px; padding-right: 5px"
        >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
      >
      <button
        v-on:click="next()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage < paging.TotalPage"
      >
        Next
      </button>
      <button
        v-if="paging.CurrentPage < paging.TotalPage"
        v-on:click="last()"
        class="btn btn-sm btn-link"
      >
        Last
      </button>
    </div>
    <!-- /Pagination -->
    <div v-if="!leaveEntitlements.length" class="text-center mt-3">
      No records available
    </div>
    <div class="text-center mt-3">
      <button
        style="width: fit-content"
        class="form-control btn btn-sm btn-secondary"
        @click="$router.push({ name: 'employeeLeaveEntitlementIndex' })"
      >
        <i class="fa fa-reply"></i>&nbsp; Back
      </button>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      employeeName: "",
      token: "",
      organisationName: "",
      leaveEntitlementOrganisationType: "",
      leaveEntitlements: [],
      //for filter
      allLeaveTypes: [],
      leaveTypesDefault: [],
      userLeaveTitles: [],
      years: [],
      leaveTypeDesc: "",
      leaveTitle: "",
      year: "",
      //for pagination
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
    };
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getLeaveEntitlementList();
    },
  },
  methods: {
    view() {
      this.paging.CurrentPage = 1;
      this.getLeaveEntitlementList();
    },
    getLeaveTypeListForDropdown() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getLeaveTypeListForDropdown(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }
          let leaveType = API.constant.leaveType;
          this.allLeaveTypes = response.data.allLeaveTypes;
          this.leaveTypesDefault = response.data.allLeaveTypes.filter(
            (c) =>
              c.Description != leaveType.FullDayAnnual &&
              c.Description != leaveType.HalfDayAnnualMorning &&
              c.Description != leaveType.HalfDayAnnualAfternoon &&
              c.IsCustom == false
          );
          this.userLeaveTitles = response.data.userLeaveTitles;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getLeaveEntitlementYearsForDropdown() {
      let param = {
        Token: this.token,
      };
      API.common
        .getLeaveEntitlementYearsForDropdown(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }
          this.years = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getLeaveEntitlementList() {
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.leaveTypeDesc == "Others"
            ? this.leaveTitle
            : this.leaveTypeDesc)
      );
      let param = {
        Token: this.token,
        UserId: this.$route.params.id,
        LeaveTypeId: leaveType ? leaveType.Id : "",
        IsOthersSelected: this.leaveTypeDesc == "Others",
        Year: this.year,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getLeaveEntitlementList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }
          if (response.data.success) {
            //console.log("getLeaveEntitlementList", response.data);
            this.leaveEntitlements = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getEmployeeDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getEmployeeDetail(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }
          if (response.data.success) {
            let emp = response.data.data;
            this.employeeName = emp.Name;
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for pagination
    first() {
      this.paging.CurrentPage = 1;
      this.getLeaveEntitlementList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getLeaveEntitlementList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getLeaveEntitlementList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getLeaveEntitlementList();
    },
  },
  created() {
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    this.token = data.Token;
    this.organisationName = data.OrganisationName;
    this.leaveEntitlementOrganisationType =
      data.LeaveEntitlementOrganisationType;

    this.getEmployeeDetail();
    this.getLeaveTypeListForDropdown();
    this.getLeaveEntitlementList();
    this.getLeaveEntitlementYearsForDropdown();
  },
};
</script>

<style scoped>
.fit-content {
  max-width: fit-content;
}
@media only screen and (max-width: 768px) {
  /* for mobile phone */
  .fit-content {
    max-width: none;
  }
}
</style>