import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userDetail: {
      Id: "",
      Username: "",
      Email: "",
      Name: "",
      Active: false,
      Token: ""
    },
    isLoading: false,
    //to retain the search filter upon redirecting to new page
    employeeFilter: {
      employeeName: "",
      organisationId: "",
      status: ""
    },
    documentFilter: {
      employeeName: "",
      organisationId: ""
    },
    leaveApplicationFilter: {
      employeeName: "",
      organisationId: "",
      leaveTypeDesc: "",
      leaveTitle: ""
    },
    organisationFilter: {
      organisationName: "",
      status: ""
    },

  }
})