<template>
  <div class="page-content-emp mb-3">
    <h4>Employees' Leave Applications</h4>
    <hr class="my-0" />
    <!-- FILTER -->
    <form class="mt-3" @submit.prevent>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Employee Name</label>
          <input
            type="text"
            placeholder="Search Name Here"
            class="form-control form-control-sm"
            v-model="name"
          />
        </div>

        <div class="form-group col-md-3 fit-content">
          <label>Leave Type</label>
          <select v-model="leaveTypeDesc" class="form-control form-control-sm">
            <option value selected>All</option>
            <option
              v-for="(item, i) in leaveTypes"
              :value="item.Description"
              :key="i"
            >
              {{ item.Description }}
            </option>
            <option value="Others" v-if="leaveTitles.length">Others</option>
          </select>
        </div>
        <div
          class="form-group col-md-2 fit-content"
          v-if="leaveTypeDesc == 'Others'"
        >
          <label>Title</label>
          <select v-model="leaveTitle" class="form-control form-control-sm">
            <option value selected>All</option>
            <option
              v-for="(title, i) in leaveTitles"
              :value="title.Description"
              :key="i"
            >
              {{ title.Description }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 fit-content">
          <label>Start Date</label>
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="fromDate"
          />
        </div>
        <div class="form-group col-md-2 fit-content">
          <label>End Date</label>
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="toDate"
          />
        </div>
        <div class="form-group col-md-1">
          <button
            class="btn btn-sm btn-primary form-control"
            style="margin-top: 1.6rem; width: fit-content"
            @click="viewAll"
          >
            View All
          </button>
        </div>
      </div>
    </form>
    <!-- LIST -->
    <div class="card mt-3" v-for="item in leaveApplications" :key="item.Id">
      <h6 class="card-header">
        <b>{{ item.LeaveTypeDescription }}</b>
      </h6>
      <div class="card-body">
        <div class="row">
          <div :class="item.Status == 'Pending Approval' ? 'col-9' : 'col-12'">
            <div class="row">
              <div class="col-3">
                <label><b>Name</b></label
                ><br />
                {{ item.EmployeeName }}
              </div>
              <div class="col-3">
                <label><b>Email</b></label
                ><br />
                {{ item.EmployeeEmail }}
              </div>
              <div class="col-3">
                <label><b>Created Date</b></label
                ><br />
                {{ item.CreatedDateStrEmp }}
              </div>
              <div class="col-3">
                <label><b>Status</b></label
                ><br />
                {{ item.Status }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3">
                <label><b>Start Date</b></label
                ><br />
                {{ item.StartDateDisplayEmp }}
              </div>
              <div class="col-3">
                <label><b>End Date</b></label
                ><br />
                {{ item.EndDateDisplayEmp }}
              </div>
              <div class="col-3">
                <label><b>Number of Leave Days</b></label
                ><br />
                {{ item.NumberOfLeaveDays }}
              </div>
              <div class="col-3">
                <label><b>Remarks</b></label
                ><br />
                {{ item.Remarks }}
              </div>
            </div>
            <div
              class="row mt-3"
              v-if="item.LeaveTypeDescription == 'Medical Leave'"
            >
              <div class="col-12">
                <label><b>Medical Certificate</b></label>
                <download-mc-button
                  :mcFileName="item.MedicalCertificateFileName"
                  :leaveApplicationId="item.Id"
                ></download-mc-button>
              </div>
            </div>
          </div>
          <div
            class="col-3 my-auto text-center"
            v-if="item.Status == 'Pending Approval'"
          >
            <button
              class="btn btn-sm btn-success mr-2"
              v-if="item.Status == 'Pending Approval'"
              @click="approve(item.Id)"
            >
              Approve
            </button>
            <button
              class="btn btn-sm btn-danger"
              v-if="item.Status == 'Pending Approval'"
              @click="reject(item.Id)"
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- PAGING -->
    <div v-if="leaveApplications.length" class="mt-3">
      Displaying
      <select
        class="custom-select custom-select-sm"
        v-model="paging.SizePerPage"
        style="width: 65px; margin-right: 10px; margin-left: 5px"
      >
        <option
          v-for="item in paging.Sizes"
          v-bind:key="item"
          v-bind:value="item"
        >
          {{ item }}
        </option>
      </select>
      <button
        v-if="paging.CurrentPage > 1"
        v-on:click="first()"
        class="btn btn-sm btn-link"
      >
        First
      </button>
      <button
        v-on:click="prev()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage > 1"
      >
        Prev
      </button>
      <span style="padding-left: 5px; padding-right: 5px"
        >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
      >
      <button
        v-on:click="next()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage < paging.TotalPage"
      >
        Next
      </button>
      <button
        v-if="paging.CurrentPage < paging.TotalPage"
        v-on:click="last()"
        class="btn btn-sm btn-link"
      >
        Last
      </button>
    </div>
    <div v-if="!leaveApplications.length" class="text-center mt-3">
      No records available
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
import DownloadMcButton from "../../Shared/DownloadMedicalCertificateButton.vue";
export default {
  components: {
    DownloadMcButton,
  },
  data() {
    return {
      name: "",
      leaveApplications: [],
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
      //for filter
      leaveTypeDesc: "",
      leaveTypes: [],
      leaveTitle: "",
      leaveTitles: [],
      allLeaveTypes: [],
      fromDate: "",
      toDate: "",
    };
  },
  computed: {
    token() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Token;
    },
    approverEmail() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Email;
    },
    approverUserId() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Id;
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
  },
  methods: {
    first() {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getLeaveApplicationList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getLeaveApplicationList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getLeaveApplicationList();
    },
    viewAll() {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
    getLeaveApplicationList() {
      //date validation
      if (this.fromDate && this.toDate) {
        let fDate = new Date(this.fromDate);
        let tDate = new Date(this.toDate);
        if (fDate > tDate) {
          this.$swal("Start Date cannot be greater than End Date", {
            icon: "error",
          });
          return;
        }
      }
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.leaveTypeDesc == "Others"
            ? this.leaveTitle
            : this.leaveTypeDesc)
      );
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        Name: this.name,
        LeaveTypeId: leaveType ? leaveType.Id : "",
        ApproverUserId: this.approverUserId,
        ApproverEmail: this.approverEmail,
        IsOthersSelected: this.leaveTypeDesc == "Others",
        FromDate: this.fromDate,
        ToDate: this.toDate,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getLeaveApplicationList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            //console.log("getLeaveApplicationList", response.data.data);
            this.leaveApplications = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getLeaveTypeListForDropdown() {
      let param = {
        Token: this.token,
        ApproverEmail: this.approverEmail,
      };
      API.admin
        .getLeaveTypeListForDropdown(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          this.allLeaveTypes = response.data.allLeaveTypes;
          this.leaveTypes = response.data.allLeaveTypes.filter(
            (c) =>
              c.Description != API.constant.leaveType.Annual &&
              c.IsCustom == false
          );
          this.leaveTitles = response.data.userLeaveTitles;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    approve(leaveApplicationId) {
      let param = {
        Token: this.token,
        Id: leaveApplicationId,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to approve the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willApprove) => {
        if (willApprove) {
          this.$store.state.isLoading = true;
          API.employee
            .approveLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  {
                    icon: "error",
                  }
                );
                this.$router.replace({ name: "login" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.getLeaveApplicationList();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    reject(leaveApplicationId) {
      let param = {
        Token: this.token,
        Id: leaveApplicationId,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to reject the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willReject) => {
        if (willReject) {
          this.$store.state.isLoading = true;
          API.employee
            .rejectLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "login" });
                return;
              }
              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.getLeaveApplicationList();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
  },
  created() {
    this.getLeaveTypeListForDropdown();
    this.getLeaveApplicationList();
  },
};
</script>

<style scoped>
.fit-content {
  max-width: fit-content;
}
@media only screen and (max-width: 768px) {
  /* for mobile phone */
  .fit-content {
    max-width: none;
  }
}
</style>