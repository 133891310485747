<template>
  <div class="page-content-emp mb-3">
    <h4>Leave Application</h4>
    <hr class="my-0" />
    <form class="mt-3" @submit.prevent="applyLeaveApplication">
      <div class="form-group">
        <label>Name</label>
        <input disabled type="text" class="form-control" v-model="name" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input disabled type="text" class="form-control" v-model="email" />
      </div>
      <div class="form-group">
        <label>Leave Type<span class="text-danger">*</span></label>
        <select v-model="selectedLeaveType" class="form-control" required>
          <option value selected>-- Select Leave Type --</option>
          <option
            v-for="(item, i) in leaveTypes"
            :value="item.Description"
            :key="i"
          >
            {{ item.Description }}
          </option>
          <option value="Others" v-if="leaveTitles.length">Others</option>
        </select>
      </div>
      <div class="form-group" v-if="selectedLeaveType == 'Others'">
        <label>Title<span class="text-danger">*</span></label>
        <select v-model="selectedLeaveTitle" class="form-control" required>
          <option value selected>-- Select Leave Title --</option>
          <option
            v-for="(item, i) in leaveTitles"
            :value="item.Description"
            :key="i"
          >
            {{ item.Description }}
          </option>
        </select>
      </div>
      <div class="form-group" v-if="selectedLeaveType == 'Medical Leave'">
        <div class="row">
          <div class="col-md-6">
            <label
              >Medical Certificate<span class="text-danger mr-1">*</span>
              <span style="font-size: 0.7rem"
                ><i
                  >(Maximum file size: 5 MB. Only zip, pdf, jpg, jpeg, png, doc,
                  docx are allowed)</i
                ></span
              >
            </label>
            <input
              type="file"
              class="custom-file-input"
              accept=".zip,.pdf,.jpg,.jpeg,.png,.doc,.docx"
              id="mcFileUpload"
              ref="mcFileUpload"
              @change="onMcSelected($event)"
              required
            />
            <label class="custom-file-label mt-4 ml-3" for="mcFileUpload">{{
              mcFileName
            }}</label>
          </div>
          <div class="col-md-1 pt-4 mt-1">
            <a
              v-if="mcFile"
              href="javascript:void(0)"
              id="removeMcFile"
              title="Remove Medical Certificate"
              @click.prevent="removeMcFile"
              ><i class="fa fa-trash-alt" style="color: grey"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label
          >Remarks<span v-if="isOthersSelected" class="text-danger"
            >*</span
          ></label
        >
        <textarea
          v-model="remarks"
          class="form-control"
          :required="isOthersSelected"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-5">
          <label>Start Date<span class="text-danger">*</span></label>
          <input
            :disabled="!leaveTypeId"
            :max="isHalfDaySelected ? null : endDate"
            type="date"
            v-model="startDate"
            class="form-control"
            placeholder="Input Start Date Here"
            required
          />
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5">
          <label>End Date<span class="text-danger">*</span></label>
          <input
            :min="startDate"
            :disabled="isHalfDaySelected || !leaveTypeId"
            type="date"
            v-model="endDate"
            class="form-control"
            placeholder="Input End Date Here"
            required
          />
        </div>
      </div>
      <div class="form-group">
        <label>Number of Leave Days</label>
        <input
          required
          disabled
          type="number"
          v-model="noOfLeaveDays"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Approver<span class="text-danger">*</span></label>
        <input
          required
          type="text"
          v-model="approverName"
          class="form-control"
          placeholder="Input Approver Name Here"
        />
      </div>
      <div class="form-group">
        <label>Approver Email<span class="text-danger">*</span></label>
        <input
          required
          type="email"
          v-model="approverEmail"
          class="form-control"
          placeholder="Input Approver Email Here"
        />
      </div>
      <input
        required
        type="checkbox"
        id="chkApprovalObtained"
        style="cursor: pointer"
        v-model="isApprovalObtained"
      />&nbsp;
      <label for="chkApprovalObtained" style="cursor: pointer">
        I have obtained APPROVAL from my boss/manager/supervisor</label
      >
      <div class="text-center">
        <button class="btn btn-primary" type="submit">Apply Now</button>
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      mcFile: null,
      mcFileName: "Upload Medical Certificate",
      name: "",
      email: "",
      userId: "",
      token: "",
      allLeaveTypes: [],
      leaveTypes: [],
      leaveTitles: [],
      selectedLeaveType: "",
      selectedLeaveTitle: "",
      remarks: "",
      isOthersSelected: false,
      isHalfDaySelected: false,
      isMaternityPaternitySelected: false,
      startDate: "",
      endDate: "",
      noOfLeaveDays: null,
      approverName: "",
      approverEmail: "",
      isApprovalObtained: false,
    };
  },
  computed: {
    leaveTypeId() {
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.selectedLeaveType == "Others"
            ? this.selectedLeaveTitle
            : this.selectedLeaveType)
      );
      return leaveType ? leaveType.Id : "";
    },
    actualLeaveTypeDescription() {
      return this.selectedLeaveType == "Others"
        ? this.selectedLeaveTitle
        : this.selectedLeaveType;
    },
  },
  watch: {
    startDate(newVal) {
      if (!newVal) return;
      if (!this.isMaternityPaternitySelected) {
        //check weekend
        if (this.isWeekend(newVal)) {
          this.startDate = null;
          this.noOfLeaveDays = null;
          this.$swal("Unable to select weekend", { icon: "error" });
          return;
        }
        //check public holiday
        API.admin
          .isPublicHoliday({ Token: this.token, Date: newVal })
          .then((response) => {
            if (response.data.message == "Unauthorized access") {
              this.$swal("Multiple login detected. You have been logged out", {
                icon: "error",
              });
              this.$router.replace({ name: "login" });
              return;
            }

            if (response.data.success) {
              if (response.data.isPublicHoliday) {
                this.startDate = null;
                this.noOfLeaveDays = null;
                this.$swal("Unable to select public holiday", {
                  icon: "error",
                });
              } else {
                if (this.isHalfDaySelected) {
                  this.endDate = this.startDate;
                  this.noOfLeaveDays = 0.5;
                } else {
                  this.calculateNoOfLeaveDays();
                }
              }
            } else {
              this.startDate = null;
              this.noOfLeaveDays = null;
              this.$swal(response.data.message, { icon: "error" });
            }
          })
          .catch((error) => {
            this.startDate = null;
            this.noOfLeaveDays = null;
            this.$swal(error.response.data.Message, { icon: "error" });
          });
      } else {
        this.calculateNoOfLeaveDays();
      }
    },
    endDate(newVal) {
      if (!newVal) return;
      if (!this.isMaternityPaternitySelected) {
        if (this.isWeekend(newVal)) {
          this.endDate = null;
          this.noOfLeaveDays = null;
          this.$swal("Unable to select weekend", { icon: "error" });
          return;
        }
        API.admin
          .isPublicHoliday({ Token: this.token, Date: newVal })
          .then((response) => {
            if (response.data.message == "Unauthorized access") {
              this.$swal("Multiple login detected. You have been logged out", {
                icon: "error",
              });
              this.$router.replace({ name: "login" });
              return;
            }

            if (response.data.success) {
              if (response.data.isPublicHoliday) {
                this.endDate = null;
                this.noOfLeaveDays = null;
                this.$swal("Unable to select public holiday", {
                  icon: "error",
                });
              } else {
                if (!this.isHalfDaySelected) {
                  this.calculateNoOfLeaveDays();
                }
              }
            } else {
              this.endDate = null;
              this.noOfLeaveDays = null;
              this.$swal(response.data.message, { icon: "error" });
            }
          })
          .catch((error) => {
            this.endDate = null;
            this.noOfLeaveDays = null;
            this.$swal(error.response.data.Message, { icon: "error" });
          });
      } else {
        this.calculateNoOfLeaveDays();
      }
    },
    selectedLeaveType() {
      //reset
      this.isOthersSelected = false;
      this.isHalfDaySelected = false;
      this.isMaternityPaternitySelected = false;
      this.startDate = "";
      this.endDate = "";
      this.noOfLeaveDays = null;
      this.isApprovalObtained = false;
      this.mcFile = null;
      this.mcFileName = "Upload Medical Certificate";
      //check
      let leaveType = API.constant.leaveType;
      if (this.selectedLeaveType == leaveType.Others) {
        this.isOthersSelected = true;
      } else if (
        this.selectedLeaveType == leaveType.HalfDayAnnualMorning ||
        this.selectedLeaveType == leaveType.HalfDayAnnualAfternoon
      ) {
        this.isHalfDaySelected = true;
        this.noOfLeaveDays = 0.5;
      } else if (this.selectedLeaveType == leaveType.MaternityPaternity) {
        this.isMaternityPaternitySelected = true;
      }
    },
  },
  methods: {
    applyLeaveApplication() {
      if (!this.noOfLeaveDays) {
        this.$swal("Invalid Number of Leave Days", { icon: "error" });
        return;
      }
      let param = {
        Token: this.token,
        EmployeeId: this.userId,
        LeaveTypeId: this.leaveTypeId,
        LeaveTypeDescription: this.actualLeaveTypeDescription,
        Remarks: this.remarks,
        StartDateStr: this.startDate,
        EndDateStr: this.endDate,
        NumberOfLeaveDays: this.noOfLeaveDays,
        ApproverName: this.approverName,
        ApproverEmail: this.approverEmail,
        MedicalCertificateFile: this.mcFile,
        MedicalCertificateFileName: this.mcFileName,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to apply the leave?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          this.$store.state.isLoading = true;
          API.employee
            .applyLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "login" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success)
                this.$router.replace({ name: "myLeaveApplicationIndex" });
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    isWeekend(dateVal) {
      let date = new Date(dateVal);
      if (date.getDay() == 6 || date.getDay() == 0) {
        return true;
      }
      return false;
    },
    calculateNoOfLeaveDays() {
      if (!this.startDate || !this.endDate) return null;
      let param = {
        Token: this.token,
        StartDateStr: this.startDate,
        EndDateStr: this.endDate,
        LeaveTypeDescription: this.selectedLeaveType,
      };
      API.admin
        .calculateNoOfLeaveDays(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            this.noOfLeaveDays = response.data.leaveDays;
          } else {
            this.$swal(response.data.message, { icon: "error" });
            this.noOfLeaveDays = null;
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
          this.noOfLeaveDays = null;
        });
    },
    //for file upload
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    onMcSelected(event) {
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > 5 * 1024 * 1024) {
        this.$swal("File size exceeds 5 MB", { icon: "error" });
        return;
      }
      this.mcFileName = file.name;
      let self = this;
      let promise = self.getBase64(file);
      promise.then(function (result) {
        self.mcFile = result;
      });
    },
    removeMcFile() {
      this.mcFile = null;
      this.mcFileName = "Upload Medical Certificate";
      this.$refs.mcFileUpload.value = null;
    },
  },
  created() {
    //populate name and email
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    this.name = data.Name;
    this.email = data.Email;
    this.userId = data.Id;
    this.token = data.Token;
    //populate leave type
    API.admin
      .getLeaveTypesByEmployeeId({
        Token: data.Token,
        Id: data.Id,
      })
      .then((response) => {
        if (response.data.message == "Unauthorized access") {
          this.$swal("Multiple login detected. You have been logged out", {
            icon: "error",
          });
          this.$router.replace({ name: "login" });
          return;
        }

        this.allLeaveTypes = response.data.allLeaveTypes;
        this.leaveTypes = response.data.allLeaveTypes.filter(
          (c) => c.IsCustom == false
        );
        this.leaveTitles = response.data.userLeaveTitles;
      })
      .catch((error) => {
        this.$swal(error.response.data.Message, { icon: "error" });
      });
  },
};
</script>