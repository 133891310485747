<template>
  <div>
    <h4>Update Leave Application</h4>
    <form
      class="mt-4 mb-5 page-content"
      @submit.prevent="updateLeaveApplication"
    >
      <div class="form-group">
        <label>Organisation<span class="text-danger">*</span></label>
        <select v-model="organisationId" class="form-control" required disabled>
          <option value selected>-- Select Organisation --</option>
          <option v-for="org in organisations" :value="org.Id" :key="org.Id">
            {{ org.Name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Employee<span class="text-danger">*</span></label>
        <select v-model="employeeId" class="form-control" required disabled>
          <option value selected>-- Select Employee --</option>
          <option v-for="emp in employees" :value="emp.Id" :key="emp.Id">
            {{ emp.Name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Leave Type<span class="text-danger">*</span></label>
        <select
          v-model="selectedLeaveType"
          class="form-control"
          required
          @change="triggerChange = true"
        >
          <option value selected>-- Select Leave Type --</option>
          <option
            v-for="(item, i) in leaveTypes"
            :value="item.Description"
            :key="i"
          >
            {{ item.Description }}
          </option>
          <option value="Others" v-if="leaveTitles.length">Others</option>
        </select>
      </div>
      <div class="form-group" v-if="selectedLeaveType == 'Others'">
        <label>Title<span class="text-danger">*</span></label>
        <select v-model="selectedLeaveTitle" class="form-control" required>
          <option value selected>-- Select Leave Title --</option>
          <option
            v-for="(item, i) in leaveTitles"
            :value="item.Description"
            :key="i"
          >
            {{ item.Description }}
          </option>
        </select>
      </div>
      <!-- Start: Download MC File  -->
      <div class="form-group" v-if="selectedLeaveType == 'Medical Leave'">
        <div class="row">
          <div class="col-md-6">
            <label
              >Medical Certificate<span class="text-danger mr-1">*</span>
              <span style="font-size: 0.7rem"
                ><i
                  >(Maximum file size: 5 MB. Only zip, pdf, jpg, jpeg, png, doc,
                  docx are allowed)</i
                ></span
              >
            </label>
            <input
              type="file"
              class="custom-file-input"
              accept=".zip,.pdf,.jpg,.jpeg,.png,.doc,.docx"
              id="mcFileUpload"
              ref="mcFileUpload"
              @change="onMcSelected($event)"
              :required="isMcFileChanged"
            />
            <label class="custom-file-label mt-4 ml-3" for="mcFileUpload">{{
              mcFileName
            }}</label>
          </div>
          <div class="col-md-1 pt-4 mt-1">
            <a
              v-if="mcFile"
              href="javascript:void(0)"
              id="removeMcFile"
              title="Remove Medical Certificate"
              @click.prevent="removeMcFile"
              ><i class="fa fa-trash-alt" style="color: grey"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- End: Download MC File -->
      <div class="form-group">
        <label
          >Remarks<span v-if="isOthersSelected" class="text-danger"
            >*</span
          ></label
        >
        <textarea
          v-model="remarks"
          class="form-control"
          :required="isOthersSelected"
        />
      </div>
      <div class="form-row">
        <div class="form-group col-md-5">
          <label>Start Date<span class="text-danger">*</span></label>
          <input
            :disabled="!leaveTypeId"
            :max="isHalfDaySelected ? null : endDate"
            type="date"
            v-model="startDate"
            class="form-control"
            placeholder="Input Start Date Here"
            required
            @input="triggerChange = true"
          />
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5">
          <label>End Date<span class="text-danger">*</span></label>
          <input
            :min="startDate"
            :disabled="isHalfDaySelected || !leaveTypeId"
            type="date"
            v-model="endDate"
            class="form-control"
            placeholder="Input End Date Here"
            required
            @input="triggerChange = true"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Number of Leave Days</label>
        <input
          required
          disabled
          type="number"
          v-model="noOfLeaveDays"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Approver<span class="text-danger">*</span></label>
        <input
          required
          type="text"
          v-model="approverName"
          class="form-control"
          placeholder="Input Approver Name Here"
        />
      </div>
      <div class="form-group">
        <label>Approver Email<span class="text-danger">*</span></label>
        <input
          required
          type="email"
          v-model="approverEmail"
          class="form-control"
          placeholder="Input Approver Email Here"
        />
      </div>
      <div class="text-center">
        <button class="btn btn-primary mr-3" type="submit">Update</button
        >&nbsp;&nbsp;
        <router-link
          class="btn btn-danger"
          :to="{ name: 'adminLeaveApplicationIndex' }"
          >Cancel</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      mcFile: null,
      mcFileName: "Upload Medical Certificate",
      organisationId: "",
      employeeId: "",
      organisations: [],
      employees: [],
      allLeaveTypes: [],
      leaveTypes: [],
      leaveTitles: [],
      selectedLeaveType: "",
      selectedLeaveTitle: "",
      remarks: "",
      isOthersSelected: false,
      isHalfDaySelected: false,
      isMaternityPaternitySelected: false,
      approverName: "",
      approverEmail: "",
      startDate: "",
      endDate: "",
      noOfLeaveDays: null,
      triggerChange: false,
      isMcFileChanged: false,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
    leaveTypeId() {
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.selectedLeaveType == "Others"
            ? this.selectedLeaveTitle
            : this.selectedLeaveType)
      );
      return leaveType ? leaveType.Id : "";
    },
    actualLeaveTypeDescription() {
      return this.selectedLeaveType == "Others"
        ? this.selectedLeaveTitle
        : this.selectedLeaveType;
    },
  },
  watch: {
    startDate(newVal, oldVal) {
      if (!newVal || !this.triggerChange) return;
      if (!this.isMaternityPaternitySelected) {
        //check weekend
        if (this.isWeekend(newVal)) {
          this.startDate = oldVal;
          this.$swal("Unable to select weekend", { icon: "error" });
          return;
        }
        //check public holiday
        API.admin
          .isPublicHoliday({ Token: this.token, Date: newVal })
          .then((response) => {
            if (response.data.success) {
              if (response.data.isPublicHoliday) {
                this.startDate = oldVal;
                this.$swal("Unable to select public holiday", {
                  icon: "error",
                });
              } else {
                if (this.isHalfDaySelected) {
                  this.endDate = this.startDate;
                  this.noOfLeaveDays = 0.5;
                } else {
                  this.calculateNoOfLeaveDays();
                }
              }
            } else {
              this.startDate = oldVal;
              this.$swal(response.data.message, { icon: "error" });
            }
          })
          .catch((error) => {
            this.startDate = oldVal;
            this.$swal(error.response.data.Message, { icon: "error" });
          });
      } else {
        this.calculateNoOfLeaveDays();
      }
    },
    endDate(newVal, oldVal) {
      if (!newVal || !this.triggerChange) return;
      if (!this.isMaternityPaternitySelected) {
        if (this.isWeekend(newVal)) {
          this.endDate = oldVal;
          this.$swal("Unable to select weekend", { icon: "error" });
          return;
        }
        API.admin
          .isPublicHoliday({ Token: this.token, Date: newVal })
          .then((response) => {
            if (response.data.success) {
              if (response.data.isPublicHoliday) {
                this.endDate = oldVal;
                this.$swal("Unable to select public holiday", {
                  icon: "error",
                });
              } else {
                if (!this.isHalfDaySelected) {
                  this.calculateNoOfLeaveDays();
                }
              }
            } else {
              this.endDate = oldVal;
              this.$swal(response.data.message, { icon: "error" });
            }
          })
          .catch((error) => {
            this.endDate = oldVal;
            this.$swal(error.response.data.Message, { icon: "error" });
          });
      } else {
        this.calculateNoOfLeaveDays();
      }
    },
    organisationId(newVal) {
      if (!newVal) {
        this.employees = [];
      } else {
        this.getEmployeesByOrganisationId();
      }
    },
    selectedLeaveType() {
      if (!this.triggerChange) return;
      //reset
      this.isOthersSelected = false;
      this.isHalfDaySelected = false;
      this.isMaternityPaternitySelected = false;
      this.startDate = "";
      this.endDate = "";
      this.noOfLeaveDays = null;
      this.mcFile = null;
      this.mcFileName = "Upload Medical Certificate";
      this.isMcFileChanged = true;
      //check
      let leaveType = API.constant.leaveType;
      if (this.selectedLeaveType == leaveType.Others) {
        this.isOthersSelected = true;
      } else if (
        this.selectedLeaveType == leaveType.HalfDayAnnualMorning ||
        this.selectedLeaveType == leaveType.HalfDayAnnualAfternoon
      ) {
        this.isHalfDaySelected = true;
        this.noOfLeaveDays = 0.5;
      } else if (this.selectedLeaveType == leaveType.MaternityPaternity) {
        this.isMaternityPaternitySelected = true;
      }
    },
  },
  methods: {
    updateLeaveApplication() {
      if (!this.noOfLeaveDays) {
        this.$swal("Invalid Number of Leave Days", { icon: "error" });
        return;
      }
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
        EmployeeId: this.employeeId,
        LeaveTypeId: this.leaveTypeId,
        LeaveTypeDescription: this.actualLeaveTypeDescription,
        Remarks: this.remarks,
        StartDateStr: this.startDate,
        EndDateStr: this.endDate,
        NumberOfLeaveDays: this.noOfLeaveDays,
        ApproverName: this.approverName,
        ApproverEmail: this.approverEmail,
        MedicalCertificateFile: this.mcFile,
        MedicalCertificateFileName: this.mcFileName,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to update the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willUpdate) => {
        if (willUpdate) {
          this.$store.state.isLoading = true;
          API.admin
            .updateLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success)
                this.$router.replace({ name: "adminLeaveApplicationIndex" });
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    isWeekend(dateVal) {
      let date = new Date(dateVal);
      if (date.getDay() == 6 || date.getDay() == 0) {
        return true;
      }
      return false;
    },
    calculateNoOfLeaveDays() {
      if (!this.startDate || !this.endDate) return null;
      let param = {
        Token: this.token,
        StartDateStr: this.startDate,
        EndDateStr: this.endDate,
        LeaveTypeDescription: this.selectedLeaveType,
      };
      API.admin
        .calculateNoOfLeaveDays(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.noOfLeaveDays = response.data.leaveDays;
          } else {
            this.$swal(response.data.message, { icon: "error" });
            this.noOfLeaveDays = null;
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
          this.noOfLeaveDays = null;
        });
    },
    getLeaveTypesByEmployeeId() {
      API.admin
        .getLeaveTypesByEmployeeId({
          Token: this.token,
          Id: this.employeeId,
        })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.allLeaveTypes = response.data.allLeaveTypes;
          this.leaveTypes = response.data.allLeaveTypes.filter(
            (c) => c.IsCustom == false
          );
          this.leaveTitles = response.data.userLeaveTitles;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getOrganisationListForDropdown() {
      API.admin
        .getOrganisationListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.organisations = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getEmployeesByOrganisationId() {
      API.admin
        .getEmployeesByOrganisationId({
          Token: this.token,
          Id: this.organisationId,
        })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.employees = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getLeaveApplicationDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getLeaveApplicationDetail(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            let data = response.data.data;
            this.organisationId = data.OrganisationId;
            this.employeeId = data.EmployeeId;
            this.selectedLeaveType = data.SelectedLeaveType;
            this.selectedLeaveTitle = data.SelectedLeaveTitle;
            this.remarks = data.Remarks;
            this.approverName = data.ApproverName;
            this.approverEmail = data.ApproverEmail;
            this.startDate = data.StartDateCal;
            this.endDate = data.EndDateCal;
            this.noOfLeaveDays = data.NumberOfLeaveDays;
            this.mcFile = data.MedicalCertificateFile;
            this.mcFileName = data.MedicalCertificateFileName;
            //deterimine isOthersSelected,isHalfDaySelected,isMaternityPaternitySelected
            let selectedLeaveType = data.SelectedLeaveType;
            let leaveType = API.constant.leaveType;
            if (selectedLeaveType == leaveType.Others) {
              this.isOthersSelected = true;
            } else if (
              selectedLeaveType == leaveType.HalfDayAnnualMorning ||
              selectedLeaveType == leaveType.HalfDayAnnualAfternoon
            ) {
              this.isHalfDaySelected = true;
            } else if (selectedLeaveType == leaveType.MaternityPaternity) {
              this.isMaternityPaternitySelected = true;
            }
            this.getLeaveTypesByEmployeeId();
          } else {
            this.$swal(response.data.message, { icon: "error" });
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for file upload
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    onMcSelected(event) {
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > 5 * 1024 * 1024) {
        this.$swal("File size exceeds 5 MB", { icon: "error" });
        return;
      }
      this.mcFileName = file.name;
      let self = this;
      let promise = self.getBase64(file);
      promise.then(function (result) {
        self.mcFile = result;
      });
    },
    removeMcFile() {
      this.mcFile = null;
      this.mcFileName = "Upload Medical Certificate";
      this.$refs.mcFileUpload.value = null;
      this.isMcFileChanged = true;
    },
  },
  created() {
    this.getOrganisationListForDropdown();
    this.getLeaveApplicationDetail();
  },
};
</script>