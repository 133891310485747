<template>
  <div>
    <h4>Employee Documents</h4>
    <div class="mt-3 page-content">
      <div class="row">
        <div class="col-sm-3">
          <label class="mr-2"><b>Employee ID: </b></label>{{ employeeId }}
        </div>
        <div class="col-sm-3">
          <label class="mr-2"><b>Name:</b></label
          >{{ employeeName }}
        </div>
        <div class="col-sm-3">
          <label class="mr-2"><b>Email:</b></label
          >{{ employeeEmail }}
        </div>
        <div class="col-sm-3">
          <label class="mr-2"><b>Organisation:</b></label
          >{{ organisationName }}
        </div>
      </div>
      <hr class="my-0" />
      <form class="mt-3" @submit.prevent="addDocument">
        <div class="form-group row">
          <label class="col-md-2 col-form-label"
            >Document<span class="text-danger mr-1">*</span
            ><i
              class="fa fa-exclamation-circle"
              title="Maximum file size: 5 MB. Only zip, pdf, jpg, jpeg, png, doc, docx, xls, xlsx are allowed"
            ></i
          ></label>
          <div class="col-md-5">
            <input
              type="file"
              class="custom-file-input"
              accept=".zip,.pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx"
              id="docFileUpload"
              ref="docFileUpload"
              @change="onDocSelected($event)"
              required
            />
            <label class="custom-file-label" for="docFileUpload">{{
              docFileName
            }}</label>
          </div>
          <div class="col-md-1 mt-2">
            <a
              v-if="docFile"
              href="javascript:void(0)"
              id="removeDocFile"
              title="Remove Document"
              @click.prevent="removeDocFile"
              ><i class="fa fa-trash-alt" style="color: grey"></i
            ></a>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label"
            >Remarks<span class="text-danger mr-1">*</span></label
          >
          <div class="col-md-5">
            <textarea class="form-control" v-model="remarks" required>
            </textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label"
            >Employer's Email<span class="text-danger mr-1">*</span></label
          >
          <div class="col-md-5">
            <input
              type="email"
              v-model="sentTo"
              class="form-control"
              required
              placeholder="Enter Employer Email Address Here"
            />
          </div>
          <div class="col-md-1">
            <button class="btn btn-primary form-control" type="submit">
              Upload
            </button>
          </div>
          <div class="col-md-3 back-btn" style="max-width: fit-content">
            <router-link
              class="btn btn-secondary form-control"
              :to="{ name: 'adminEmployeeIndex' }"
              ><i class="fa fa-reply"></i>&nbsp;Back to Employee
              List</router-link
            >
          </div>
        </div>
      </form>
      <document-table
        :token="token"
        :userId="$route.params.id"
        :showDelete="true"
        :enableNotification="false"
        :key="counter"
        class="mt-md-5"
      ></document-table>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
import DocumentTable from "../../Shared/DocumentTable.vue";
export default {
  components: {
    DocumentTable,
  },
  data() {
    return {
      employeeId: "",
      employeeName: "",
      employeeEmail: "",
      organisationName: "",
      //for document upload
      counter: 0,
      docFileName: "Upload Document",
      docFile: null,
      remarks: "",
      sentTo: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  methods: {
    reset() {
      this.removeDocFile();
      this.remarks = "";
      this.sentTo = "";
    },
    addDocument() {
      let param = {
        Token: this.token,
        UserId: this.$route.params.id,
        DocFile: this.docFile,
        DocFileName: this.docFileName,
        Remarks: this.remarks,
        SentTo: this.sentTo,
        EnableNotification: false,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to upload the document?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          this.$store.state.isLoading = true;
          API.employee
            .addDocument(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.counter++;
                this.reset();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getEmployeeDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getEmployeeDetail(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            let data = response.data.data;
            this.employeeId = data.EmployeeId;
            this.employeeName = data.Name;
            this.employeeEmail = data.Email;
            this.organisationName = data.OrganisationName;
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    onDocSelected(event) {
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > 5 * 1024 * 1024) {
        this.$swal("File size exceeds 5 MB", { icon: "error" });
        return;
      }
      this.docFileName = file.name;
      let self = this;
      let promise = self.getBase64(file);
      promise.then(function (result) {
        self.docFile = result;
      });
    },
    removeDocFile() {
      this.docFile = null;
      this.docFileName = "Upload Document";
      this.$refs.docFileUpload.value = null;
    },
  },
  created() {
    this.getEmployeeDetail();
  },
};
</script>

<style scoped>
.custom-file-label {
  margin-left: 15px;
  width: 590px;
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .custom-file-label {
    width: 405px;
  }

  .btn {
    margin-top: 20px !important;
  }

  .back-btn {
    max-width: none !important;
  }
}
</style>