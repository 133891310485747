<template>
  <div>
    <div class="overlay"></div>
    <section class="main-content full-height">
      <div class="container text-inverse">
        <div class="row">
          <div class="col-sm-8 col-md-6 col-lg-5 center-block">
            <div class="box box-mobile">
              <h3>Leave Application Login</h3>
              <hr />
              <form @submit.prevent="login">
                <fieldset>
                  <div class="alert alert-danger" v-if="errorMessage">
                    {{ errorMessage }}
                  </div>
                  <!-- INPUT -->
                  <div class="form-group">
                    <label class="control-label" for="txtCompanyCode"
                      >Company Code</label
                    >
                    <input
                      id="txtCompanyCode"
                      v-model="companyCode"
                      type="text"
                      placeholder=""
                      class="form-control input-md"
                      required
                    />
                  </div>

                  <!-- INPUT -->
                  <div class="form-group">
                    <label class="control-label" for="txtEmail"
                      >Employee ID</label
                    >
                    <input
                      id="txtEmail"
                      v-model="employeeId"
                      type="text"
                      placeholder=""
                      class="form-control input-md"
                      required
                    />
                  </div>

                  <!-- PASSWORD -->
                  <div class="form-group">
                    <label class="control-label" for="txtPassword"
                      >Password</label
                    >
                    <input
                      id="txtPassword"
                      v-model="password"
                      type="password"
                      placeholder=""
                      class="form-control input-md"
                      required
                    />
                  </div>
                  <span class="clearfix"></span>

                  <!-- BUTTON -->
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary full-width">
                      Sign In
                    </button>
                  </div>
                  <div style="text-align: center">
                    <router-link class="forgot" :to="{ name: 'forgotPassword' }"
                      >Forgot password?</router-link
                    >
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      errorMessage: "",
      companyCode: "",
      employeeId: "",
      password: "",
      //to track leave application approval request
      fromRoute: null,
    };
  },
  methods: {
    login() {
      this.errorMessage = "";
      let param = {
        CompanyCode: this.companyCode,
        EmployeeId: this.employeeId,
        Password: this.password,
      };
      this.$store.state.isLoading = true;
      API.employee
        .login(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          let success = response.data.success;
          if (success) {
            localStorage.setItem(
              "employeelmstoken",
              JSON.stringify(response.data.data)
            );
            if (
              this.fromRoute.name == "employeeLeaveApplicationApprovalRequest"
            ) {
              this.$router.replace({
                name: this.fromRoute.name,
                params: { id: this.fromRoute.params.id },
              });
            } else {
              this.$router.replace({ name: "account" });
            }
          } else {
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.log(error);
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
};
</script>

<style scoped>
section {
  background: url("/assets/bg-image.jpg") no-repeat center fixed;
  background-size: cover;
}
hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

a {
  color: #c4dc50;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:hover {
  color: #b3d029;
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}

.color.color-text {
  color: #3a3b40;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 645px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.text-inverse {
  color: #ffffff;
}

.center-block {
  margin: auto;
  top: 60px;
}

.table-center {
  text-align: center;
}

.no-margin {
  margin: 0;
}

.primary-color-text {
  color: #c4dc50;
}

.text-bold {
  font-weight: 700 !important;
}

.no-martop {
  margin-top: 0;
}

.error-message {
  color: #be1e31;
  display: none;
  font-weight: 500;
}
.error-message.active {
  display: block;
}

.display-message {
  color: #337ab7;
  font-weight: 500;
}

.success-message {
  color: #449d44;
  display: none;
  font-weight: 500;
}
.success-message.active {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.btn {
  border-radius: 3px;
  font-size: 13px;
  padding: 11px 12px;
}

.btn-primary {
  border-color: #dfdfdf;
  background-color: #c4dc50;
  color: #3a3b40;
}
.btn-primary:hover {
  background-color: #b3d029;
  border-color: #dfdfdf;
  color: #3a3b40;
}

#btnSignIn {
  margin-top: 5px;
}

.control-label {
  font-weight: 400;
}

.form-control {
  border-radius: 3px;
  height: 42px;
  box-shadow: none;
  border-color: #dfdfdf;
}
.form-control:focus {
  border-color: #b3d029;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(196, 220, 80, 0.4);
}

.form-control-bg {
  background: #ebebeb;
}

.input-group-addon {
  border-radius: 3px;
  border-color: #dfdfdf;
}

.form-control-date {
  border-right: 0;
}

.date-picker {
  border-left: transparent;
}

.navbar {
  border: none;
  border-radius: 0;
}
.navbar-inverse {
  background-color: #2f2f2f;
}
.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
  font-size: 13px;
}
.navbar-inverse .navbar-nav > li > a:hover {
  color: #c4dc50;
  background-color: #282828;
}
.navbar-inverse .navbar-nav > li.active > a {
  color: #c4dc50;
  background-color: #282828;
}
.navbar-inverse .navbar-nav > li.active > a:hover {
  color: #c4dc50;
  background-color: #282828;
}
.navbar-nav > li {
  padding: 0;
}
.navbar-nav > li > a {
  line-height: 38px;
}
.navbar-brand {
  height: auto;
}
.navbar-brand img {
  width: 170px;
  height: auto;
}

.navbar-collapse {
  padding-right: 0;
}

.dropdown-menu > li > a {
  color: #ffffff;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #ffffff;
  text-decoration: none;
  background: transparent;
}

.box {
  border: 6px solid #ffffff;
  padding: 2.5em;
  margin-top: 10px;
}
.box h3 {
  margin: 0 0 5px 0;
}

.forgot-password {
  margin-bottom: 0;
}

.card {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin-top: 30px;
}

.table {
  border: 1px solid #f6f5f5;
}
.table > thead > tr > th {
  background-color: #c4dc50;
  padding: 14px 16px;
  font-weight: 500;
  border-bottom: 0;
}
.table > tbody > tr > td {
  border-top: none;
  font-weight: 300;
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: #ffffff;
}
.table-striped > tbody > tr:nth-child(even) {
  background-color: #efefef;
}
.table-striped > tbody > tr > td {
  padding: 12px 16px;
}
.table-striped > tfoot > tr td {
  border: none;
}

.table-responsive {
  border: none;
}
.table-responsive td a {
  border-bottom: 1px solid #c4dc50;
  color: #3a3b40;
}

.main-content {
  padding-bottom: 30px;
}

.table-pagination {
  margin: 0;
}

.pagination > li:last-child > a,
.pagination > li:first-child > a {
  border-radius: 0;
}
.pagination > .active > a {
  background-color: #c4dc50;
  border-color: #e3e3e3;
  color: #3a3b40;
}
.pagination > .active > a:focus,
.pagination > .active > a:hover {
  background-color: #c4dc50;
  border-color: #e3e3e3;
  color: #3a3b40;
}
.pagination > li > a {
  background-color: #2f2f2f;
  border-color: #e3e3e3;
  color: #ffffff;
}
.pagination > li > a:hover {
  background-color: #c4dc50;
  border-color: #e3e3e3;
  color: #3a3b40;
}

.menu2 {
  background-color: #1b1b1b;
}

.menu3 {
  background-color: #0e0e0e;
}

.svg-img {
  height: auto;
  vertical-align: middle;
}

.svg-logout,
.svg-user {
  width: 12px;
  margin-right: 8px;
}

.caret {
  margin-left: 25px;
}

.nav-tab-dashboard {
  border: none;
}
.nav-tab-dashboard > li {
  float: right;
  margin-bottom: 0;
}
.nav-tab-dashboard > li:first-child {
  margin-right: 10px;
}
.nav-tab-dashboard > li > a {
  color: #3a3b40;
  border: none;
  padding: 10px 8px;
}
.nav-tab-dashboard > li > a:hover,
.nav-tab-dashboard > li a:focus {
  background-color: transparent;
  color: #c4dc50;
}
.nav-tab-dashboard > li.active > a {
  border: none;
  color: #c4dc50;
}
.nav-tab-dashboard > li.active > a:hover,
.nav-tab-dashboard > li.active a:focus {
  border: none;
  color: #c4dc50;
}

.header-table h5,
.header-table span {
  margin-top: 10px;
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.block-wrapper {
  background-color: #2f2f2f;
  color: #ffffff;
  border-left: 13px solid #c4dc50;
  padding: 20px;
  position: relative;
  margin-bottom: 30px;
}
.block-wrapper.left-side {
  margin-left: -15px;
}
.block-wrapper.right-side {
  margin-right: -15px;
}
.block-wrapper h3 {
  color: #c4dc50;
  margin-bottom: 3px;
  margin-top: 10px;
}
.block-wrapper .statistic-item {
  color: #c4dc50;
  display: inline-block;
  border-right: 1px solid #ffffff;
  padding: 0 25px;
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
}
.block-wrapper .statistic-item:first-child {
  padding-left: 0;
}
.block-wrapper .statistic-item span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  display: block;
}
.block-wrapper .block-arrow {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 18px;
  background-color: #161616;
  font-size: 38px;
  height: 100%;
}
.block-wrapper .block-arrow a {
  vertical-align: middle;
  line-height: 4;
  color: #ffffff;
}
.block-wrapper .block-arrow a:hover {
  color: #c4dc50;
}

.statistic-item a {
  text-decoration: underline;
}

.required-notif {
  border: 1px solid #ff6775;
}

.age-group1 {
  padding-left: 0;
}

.age-group2 {
  padding-right: 0;
}
.age-group2 label {
  color: #ffffff;
}

.svg-calendar {
  width: 18px;
}

.svg-user path,
.svg-logout path {
  fill: #fff;
}

.right-wrapper {
  background-color: #f7f7f7;
}

.card-campaign {
  padding-top: 15px;
  padding-bottom: 15px;
}

.bottom-card {
  background-color: #2f2f2f;
  padding: 15px;
}
.bottom-card .info {
  color: #ff6775;
  margin-bottom: 0;
  font-size: 11px;
  line-height: 36px;
  font-style: italic;
}

.btn-preview {
  padding: 8px 30px;
  margin-left: 15px;
}

.form-campaign .control-label {
  font-size: 12px;
}

.menu3 li a {
  font-size: 13px;
}

#section-coupon,
#section-mail,
.back-promotion,
.skip-coupon,
.next-preview,
.back-coupon,
.preview {
  display: none;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.redeemed .title {
  position: relative;
}

.redeemed .download {
  margin-top: 10px;
  position: absolute;
  right: 15px;
}

.container-checkbox {
  margin-top: 10px;
}
.container-checkbox label {
  margin-bottom: 0;
}

/* Extra Small devices (mobiles, 320px and up) */
@media (max-width: 320px) and (max-height: 480px) {
  .full-height-home {
    height: 104%;
  }
}
@media (max-width: 767px) {
  .navbar {
    margin-bottom: 0;
  }
  .navbar-inverse .navbar-toggle {
    border: none;
  }
  .navbar-toggle {
    padding: 10px;
    margin-top: 11px;
    margin-right: 15px;
    margin-bottom: 11px;
  }
  .navbar-toggle:focus {
    background: transparent;
  }
  .navbar-collapse {
    padding-right: 15px;
  }
  .navbar-brand img {
    width: 125px;
  }
  .box-mobile {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .overlay {
    position: fixed;
  }
  .age-group1 {
    margin-top: 15px;
    padding-left: 0;
    padding-right: 0;
  }
  .age-group2 {
    padding-left: 0;
    padding-right: 0;
  }
  .bottom-card .info {
    line-height: 1;
  }
  .btn-preview {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
  .right-wrapper {
    padding-top: 1px;
  }
  .card {
    padding: 30px 20px;
  }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .btn-preview {
    width: 100%;
  }
  .right-wrapper {
    padding-top: 1px;
  }
}
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */

.padplus {
  padding: 13.65px 19px 13.65px 19px;
  float: right;
}
.padmin {
  padding: 10.5px 15px 10.5px 15px;
  text-align: center;
}
.insm {
  height: 50px;
}
.btn-plus {
  position: absolute;
  right: 55px;
  padding: 0px 9px;
  background: black;
  color: white;
  font-weight: 600;
  border-radius: 100%;
  font-size: 20px;
  margin-top: -4px;
}
.btn-plus:hover,
.btn-min:hover,
.btn-plus-date:hover,
.btn-min-date:hover {
  cursor: pointer;
}

.btn-min {
  position: absolute;
  right: 13px;
  padding: 0px 11px;
  background: red;
  color: white;
  font-weight: 600;
  border-radius: 100%;
  font-size: 20px;
  margin-top: -35px;
}

.btn-plus-date,
.btn-min-date {
  position: absolute;
  padding: 0px 9px;
  font-weight: 600;
  border-radius: 100%;
  font-size: 20px;
  right: 246px;
  margin-top: -35px;
  background: black;
  color: white;
}

.btn-min-date {
  background: red;
  padding: 0px 11px;
}

.tab-content {
  padding: 20px 0;
}
.nav-tabs li {
  width: 25%;
}
.nav-tabs li a {
  color: #555;
  text-align: center;
}
.nav-tabs li a:focus,
.nav-tabs li.active a {
  font-weight: bold;
}
.msg-error-password {
  color: red;
  font-weight: bold;
  font-style: italic;
}
.form-campaign fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid silver;
}
legend {
  width: auto;
  margin-bottom: 0;
  font-size: 13px;
  border: none;
}
.ui-menu-item {
  padding: 0;
}
.ui-widget-content li:hover {
  background-color: #c4dc50;
}
.ui-widget-content li:hover a {
  background-color: transparent;
  color: #333333;
}
.ui-widget-content li a {
  display: block;
  padding: 10px;
}
.ui-widget-content li a.ui-state-focus {
  background-color: #c4dc50;
  border: none;
  margin: 0;
}

.profil-item {
  height: 80px;
  width: 80px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: 0 auto;
}

.upload-photo {
  color: #59595b;
  font-size: 14px;
  margin: 15px 0 15px;
}

.upload-photo .add {
  transition: background-color 0.5s ease;
  padding: 8px 20px;
  border: 1px solid #00bceb;
  color: #59595b;
  background-color: #fff;
  border-radius: 20px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.upload-photo .add:hover {
  padding: 8px 20px;
  border: 1px solid #6ebd4a;
  color: #fff;
  background-color: #6ebd4a;
}

.date_salary {
  padding-left: 0px;
  padding-right: 0px;
}
#autocomplete_name .dropdown-menu > li > a {
  color: #333333;
}

#autocomplete_name .dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}

.fontCarreer {
  font-family: "CarreerLibrary" !important;
}

.color_23 a {
  color: #d32300;
}

.color_24 a {
  color: #68b04d;
}

.font_0 {
  font: normal normal normal 29px/1.4em georgia, palatino, "book antiqua",
    "palatino linotype", serif;
  color: #606060;
}

.navbar-header h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f7f2ac;
  opacity: 1;
}

.error-message {
  font-size: 8pt;
  color: #e2211c;
  margin: 10px 0px 10px 0px;
  padding-left: 15px;
  display: inline-block;
}

#filter_date_msg {
  margin: 0 0 10px;
  padding: 0;
}
</style>

