<template>
  <div class="page-content-emp mb-3">
    <h4>Employees' Documents</h4>
    <hr class="my-0" />
    <!-- FILTER -->
    <div class="row mt-3">
      <label class="ml-3 col-form-label" style="width: fit-content"
        ><b>Employee Name</b></label
      >
      <input
        type="text"
        placeholder="Search Name Here"
        class="form-control form-control-sm mx-3"
        style="width: 20rem"
        v-model="employeeName"
      />
      <button class="btn btn-sm btn-primary" @click="view">View</button>
    </div>
    <!-- RESULT -->
    <div class="card mt-3" v-for="item in employees" :key="item.Id">
      <h6 class="card-header">
        <b>{{ item.UserName }}</b>
      </h6>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label class="mr-2"><b>Employee ID: </b></label
            >{{ item.UserEmployeeId }}
          </div>
          <div class="col-6">
            <label class="mr-2"><b>Email:</b></label
            >{{ item.UserEmail }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <document-table
              :token="token"
              :userId="item.UserId"
              :showDelete="false"
            ></document-table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!employees.length" class="text-center mt-3">
      No records available
    </div>
  </div>
</template>

<script>
import API from "../../http-common.js";
import DocumentTable from "../Shared/DocumentTable.vue";
export default {
  components: {
    DocumentTable,
  },
  data() {
    return {
      token: "",
      employerUserId: "",
      employerEmail: "",
      employeeName: "",
      employees: [],
    };
  },
  methods: {
    view() {
      this.getDocumentListByEmployerEmail();
    },
    getDocumentListByEmployerEmail() {
      let param = {
        Token: this.token,
        EmployerUserId: this.employerUserId,
        EmployerEmail: this.employerEmail,
        EmployeeName: this.employeeName,
      };
      this.$store.state.isLoading = true;
      API.employee
        .getDocumentListByEmployerEmail(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            this.employees = response.data.data;
          } else {
            this.$swal(response.data.message, { icon: "error" });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    this.token = data.Token;
    this.employerUserId = data.Id;
    this.employerEmail = data.Email;

    this.getDocumentListByEmployerEmail();
  },
};
</script>