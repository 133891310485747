<template>
  <div>
    <div class="table-responsive" v-if="documents.length">
      <table class="table table-bordered" style="width: 100%">
        <thead class="thead-light">
          <tr>
            <th v-if="isFromDocumentsMenu">Organisation</th>
            <th v-if="isFromDocumentsMenu">Employee</th>
            <th>Date Uploaded</th>
            <th>File</th>
            <th>Remarks</th>
            <th>Employer Email</th>
            <th v-if="showDelete"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="doc in documents" :key="doc.Id">
            <td v-if="isFromDocumentsMenu">{{ doc.OrganisationName }}</td>
            <td v-if="isFromDocumentsMenu">{{ doc.EmployeeName }}</td>
            <td>{{ doc.CreatedDateStr }}</td>
            <td>
              <form :action="downloadUrl" method="POST">
                <input type="hidden" name="documentId" :value="doc.Id" />
                <button type="submit" class="btn btn-link btn-sm p-0">
                  {{ doc.DocFileName }}
                </button>
              </form>
            </td>
            <td>{{ doc.Remarks }}</td>
            <td>{{ doc.SentTo }}</td>
            <td v-if="showDelete" class="align-middle">
              <a href="javascript:void(0)" @click.prevent="deleteDocument(doc)"
                >Delete</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="documents.length" class="display-page">
      Displaying
      <select
        class="custom-select custom-select-sm"
        v-model="paging.SizePerPage"
        style="width: 65px; margin-right: 10px; margin-left: 5px"
      >
        <option
          v-for="item in paging.Sizes"
          v-bind:key="item"
          v-bind:value="item"
        >
          {{ item }}
        </option>
      </select>
      <button
        v-if="paging.CurrentPage > 1"
        v-on:click="first()"
        class="btn btn-sm btn-link"
      >
        First
      </button>
      <button
        v-on:click="prev()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage > 1"
      >
        Prev
      </button>
      <span style="padding-left: 5px; padding-right: 5px"
        >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
      >
      <button
        v-on:click="next()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage < paging.TotalPage"
      >
        Next
      </button>
      <button
        v-if="paging.CurrentPage < paging.TotalPage"
        v-on:click="last()"
        class="btn btn-sm btn-link"
      >
        Last
      </button>
    </div>
    <div v-if="!documents.length" class="text-center">No records available</div>
  </div>
</template>

<script>
import API from "../../http-common.js";
import config from "../../config";
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: false,
    },
    showDelete: {
      type: Boolean,
      required: true,
    },
    enableNotification: {
      type: Boolean,
      required: false,
    },
    //for Documents menu
    isFromDocumentsMenu: {
      type: Boolean,
      required: false,
    },
    employeeName: {
      type: String,
      required: false,
    },
    organisationId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      documents: [],
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
    };
  },
  computed: {
    downloadUrl() {
      return config.API_URL + "/Home/DownloadDocFile";
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getDocumentList();
    },
  },
  methods: {
    reloadData() {
      if (this.isFromDocumentsMenu)
        this.getDocumentListByEmployeeAndOrganisation();
      else this.getDocumentList();
    },
    getDocumentListByEmployeeAndOrganisation() {
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        EmployeeName: this.employeeName,
        OrganisationId: this.organisationId,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getDocumentListByEmployeeAndOrganisation(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.success) {
            this.documents = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getDocumentList() {
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        UserId: this.userId,
      };
      this.$store.state.isLoading = true;
      API.employee
        .getDocumentList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.success) {
            this.documents = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    deleteDocument(doc) {
      let param = {
        Token: this.token,
        Id: doc.Id,
        DocFileName: doc.DocFileName,
        Remarks: doc.Remarks,
        SentTo: doc.SentTo,
        EnableNotification: this.enableNotification,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the document?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          API.employee
            .deleteDocument(param)
            .then((response) => {
              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.paging.CurrentPage = 1;
                this.reloadData();
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    //pagination
    first() {
      this.paging.CurrentPage = 1;
      this.getDocumentList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getDocumentList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getDocumentList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getDocumentList();
    },
  },
  created() {
    this.reloadData();
  },
};
</script>
