<template>
  <form :action="downloadUrl" method="POST">
    <input
      type="hidden"
      name="leaveApplicationId"
      :value="leaveApplicationId"
    />
    <button type="submit" class="btn btn-link btn-sm p-0">
      {{ mcFileName }}
    </button>
  </form>
</template>

<script>
import config from "../../config";
export default {
  props: {
    mcFileName: {
      type: String,
      required: true,
    },
    leaveApplicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    downloadUrl() {
      return config.API_URL + "/Home/DownloadMcFile";
    },
  },
  methods: {},
};
</script>
