<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark"
      style="padding-left: 1.5rem; padding-right: 1.5rem"
    >
      <div class="d-flex flex-grow-1">
        <span class="w-100 d-lg-none d-block"
          ><!-- hidden spacer to center brand on mobile --></span
        >
        <a class="navbar-brand" href="#">
          <span
            style="
              letter-spacing: 0.08rem;
              font-size: 25px;
              font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              color: #d32300;
            "
            >career</span
          >
          <span
            style="
              letter-spacing: 0.08rem;
              font-size: 25px;
              font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              color: #68b04d;
            "
            >library</span
          >
        </a>
        <div class="w-100 text-right">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#menu"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse flex-grow-1 text-right" id="menu">
        <ul class="navbar-nav ml-auto flex-nowrap">
          <router-link
            :to="{ name: 'adminDocumentIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/admin/document') ? 'nav-item active' : ''
            "
          >
            <a class="nav-link">Documents</a>
          </router-link>
          <router-link
            :to="{ name: 'adminLeaveApplicationIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/admin/leaveapplication')
                ? 'nav-item active'
                : ''
            "
          >
            <a class="nav-link">Leave Applications</a>
          </router-link>
          <router-link
            :to="{ name: 'adminOrganisationIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/admin/organisation')
                ? 'nav-item active'
                : ''
            "
          >
            <a class="nav-link">Organisation</a>
          </router-link>
          <router-link
            :to="{ name: 'adminEmployeeIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/admin/employee') ? 'nav-item active' : ''
            "
          >
            <a class="nav-link">Employees</a>
          </router-link>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-user-circle"></i>&nbsp;{{
                user ? user.Username : username
              }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <router-link class="dropdown-item" :to="{ name: 'adminProfile' }"
                >My Profile</router-link
              >
              <router-link
                class="dropdown-item"
                :to="{ name: 'adminChangePassword' }"
                >Change Password</router-link
              >
              <router-link
                class="dropdown-item"
                :to="{ name: 'adminPublicHoliday' }"
                >Public Holidays</router-link
              >
              <a class="dropdown-item" href="#" @click.prevent="logout">
                Logout&nbsp;&nbsp;<i class="fas fa-sign-out-alt"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="mt-3" style="padding-left: 1.5rem; padding-right: 1.5rem">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      username: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("adminlmstoken");
      this.$router.replace({ name: "adminLogin" });
    },
    populateUserDetail() {
      API.admin
        .getUserDetail({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.$store.state.userDetail = response.data;
          this.username = response.data.Username;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.populateUserDetail();
  },
};
</script>

<style scoped>
.dropdown-menu {
  font-size: 0.7rem;
  min-width: 5rem;
  left: unset;
  right: -5px;
}

.dropdown-item {
  padding: 0.25rem 1rem;
}

/* .nav-link:hover,
.router-link-active .nav-link,
.router-link-exact-active .nav-link{
  color:lightgrey !important;
} */

.nav-link:hover {
  color: white !important;
}
</style>