<template>
  <div class="page-content-emp mb-3">
    <h4>My Leave Applications</h4>
    <hr class="my-0" />
    <!-- LEAVE TYPE FILTER -->
    <div class="row align-items-center">
      <div class="col-md-2 mt-3 pr-0" style="max-width: fit-content">
        <b>Leave Type</b>
      </div>
      <div class="col-md-3 mt-3">
        <select
          v-model="selectedLeaveType"
          required
          class="form-control form-control-sm"
        >
          <option value selected>All Leave Types</option>
          <option
            v-for="(item, i) in leaveTypes"
            :value="item.Description"
            :key="i"
          >
            {{ item.Description }}
          </option>
          <option value="Others" v-if="leaveTitles.length">Others</option>
        </select>
      </div>
      <template v-if="selectedLeaveType == 'Others'">
        <div class="col-md-2 mt-3 pr-0" style="max-width: fit-content">
          <b>Title</b>
        </div>
        <div class="col-md-3 mt-3">
          <select
            v-model="selectedLeaveTitle"
            class="form-control form-control-sm"
            required
          >
            <option value selected>All Titles</option>
            <option
              v-for="(item, i) in leaveTitles"
              :value="item.Description"
              :key="i"
            >
              {{ item.Description }}
            </option>
          </select>
        </div>
      </template>
      <div class="col-md-1 mt-3">
        <button class="btn btn-sm btn-primary form-control" @click="view">
          View All
        </button>
      </div>
    </div>
    <!-- /LEAVE TYPE FILTER -->

    <!-- LEAVE APPLICATION LIST -->
    <div class="card mt-3" v-for="item in leaveApplications" :key="item.Id">
      <h6 class="card-header">
        <b>{{ item.LeaveTypeDescription }}</b>
      </h6>
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col-3">
                <label><b>Created Date</b></label
                ><br />
                {{ item.CreatedDateStrEmp }}
              </div>
              <div class="col-3">
                <label><b>Status</b></label
                ><br />
                {{ item.Status }}
              </div>
              <div class="col-3">
                <label><b>Approver</b></label
                ><br />
                {{ item.ApproverName }}
              </div>
              <div class="col-3">
                <label><b>Approver Email</b></label
                ><br />
                {{ item.ApproverEmail }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3">
                <label><b>Start Date</b></label
                ><br />
                {{ item.StartDateDisplayEmp }}
              </div>
              <div class="col-3">
                <label><b>End Date</b></label
                ><br />
                {{ item.EndDateDisplayEmp }}
              </div>
              <div class="col-3">
                <label><b>Number of Leave Days</b></label
                ><br />
                {{ item.NumberOfLeaveDays }}
              </div>
              <div class="col-3">
                <label><b>Remarks</b></label
                ><br />
                {{ item.Remarks }}
              </div>
            </div>
            <div
              class="row mt-3"
              v-if="item.LeaveTypeDescription == 'Medical Leave'"
            >
              <div class="col-12">
                <label><b>Medical Certificate</b></label>
                <download-mc-button
                  :mcFileName="item.MedicalCertificateFileName"
                  :leaveApplicationId="item.Id"
                ></download-mc-button>
              </div>
            </div>
          </div>
          <div class="col-3 my-auto text-center">
            <button
              class="btn btn-sm btn-secondary mr-2"
              v-if="item.Status == 'Pending Approval'"
              @click="
                $router.push({
                  name: 'myLeaveApplicationEdit',
                  params: { id: item.Id },
                })
              "
            >
              Edit
            </button>
            <button
              class="btn btn-sm btn-danger mr-2"
              @click="deleteLeaveApplication(item.Id)"
            >
              Delete
            </button>

            <button class="btn btn-sm btn-info" @click="print(item.Id)">
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- PAGING -->
    <div v-if="leaveApplications.length" class="mt-3">
      Displaying
      <select
        class="custom-select custom-select-sm"
        v-model="paging.SizePerPage"
        style="width: 65px; margin-right: 10px; margin-left: 5px"
      >
        <option
          v-for="item in paging.Sizes"
          v-bind:key="item"
          v-bind:value="item"
        >
          {{ item }}
        </option>
      </select>
      <button
        v-if="paging.CurrentPage > 1"
        v-on:click="first()"
        class="btn btn-sm btn-link"
      >
        First
      </button>
      <button
        v-on:click="prev()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage > 1"
      >
        Prev
      </button>
      <span style="padding-left: 5px; padding-right: 5px"
        >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
      >
      <button
        v-on:click="next()"
        class="btn btn-sm btn-light"
        v-if="paging.CurrentPage < paging.TotalPage"
      >
        Next
      </button>
      <button
        v-if="paging.CurrentPage < paging.TotalPage"
        v-on:click="last()"
        class="btn btn-sm btn-link"
      >
        Last
      </button>
    </div>
    <div v-if="!leaveApplications.length" class="text-center mt-3">
      No records available
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
import DownloadMcButton from "../../Shared/DownloadMedicalCertificateButton.vue";
export default {
  components: {
    DownloadMcButton,
  },
  data() {
    return {
      token: "",
      userId: "",
      leaveApplications: [],
      allLeaveTypes: [],
      leaveTypes: [],
      leaveTitles: [],
      selectedLeaveType: "",
      selectedLeaveTitle: "",
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
    };
  },
  computed: {
    leaveTypeId() {
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.selectedLeaveType == "Others"
            ? this.selectedLeaveTitle
            : this.selectedLeaveType)
      );
      return leaveType ? leaveType.Id : "";
    },
    actualLeaveTypeDescription() {
      return this.selectedLeaveType == "Others"
        ? this.selectedLeaveTitle
        : this.selectedLeaveType;
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
  },
  methods: {
    first() {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getLeaveApplicationList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getLeaveApplicationList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getLeaveApplicationList();
    },
    view() {
      this.getLeaveApplicationList();
    },
    getLeaveApplicationList() {
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        UserId: this.userId,
        LeaveTypeId: this.leaveTypeId,
        IsOthersSelected: this.selectedLeaveType == "Others",
      };
      this.$store.state.isLoading = true;
      API.admin
        .getLeaveApplicationList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            //console.log("getLeaveApplicationList", response.data);
            this.leaveApplications = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    deleteLeaveApplication(leaveApplicationId) {
      let param = {
        Token: this.token,
        Id: leaveApplicationId,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.state.isLoading = true;
          API.employee
            .deleteLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "login" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.paging.CurrentPage = 1;
                this.getLeaveApplicationList();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    print(leaveApplicationId) {
      let param = {
        Token: this.token,
        Id: leaveApplicationId,
      };
      API.admin
        .getLeaveApplicationDetail(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            let data = response.data.data;
            //printing
            let orgLogo = data.OrganisationLogo
              ? `<img style="width:100%;vertical-align:top" src="${data.OrganisationLogo}"/>`
              : "";
            let html = `<html>
            <head>
            	<style>
                @media print {
                  body {-webkit-print-color-adjust: exact;}
                }
            		@page{size:auto; margin:5mm;}
            		table, th, td {
            			border: 1px solid black;
            			border-collapse: collapse;
                  font-family: Arial, Helvetica, sans-serif;
            		}
                th, td{
                  padding: 10px;
                }  
            	</style>
              <title>LeaveApplication</title>
            </head>

            <body>
            	<table style="width:100%">
                    <tr>
                      <td style="border-right-style:none;border-bottom-style:none;padding-bottom:0">${orgLogo}</td>
                      <td style="border-left-style:none;border-bottom-style:none;border-right-style:none;width:1%"></td>
                      <td style="text-align:right; vertical-align:top;border-left-style:none;border-bottom-style:none;"><img style="width:100%;" src="${API.constant.careerLibImgBase64}"/></td>
                    </tr>
                    <tr>
                      <td colspan="3" style="border-top-style:none;padding-top:0">
                        <label style="font-size: 1.3rem; font-weight: 600"
                        >Leave Application</label><br />
                        <p style="margin-top:1rem; margin-bottom:0.5rem;">
                          <label style="width: 20px; margin: 0;font-weight: 600;">Employee&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>:
                          <label style="width: 9rem;margin: 0 0 0.1rem 0.2rem;">${data.EmployeeName}</label>
                        </p>
                        <p style="margin-top:0.5rem;">
                          <label style="width: 5.1rem;margin: 0;font-weight: 600;">Created Date</label>:
                          <label style="width: 9rem;margin: 0 0 0.1rem 0.2rem;">${data.CreatedDateStr}</label>
                        </p>
                      </td>
                    </tr>
                    <tr style="background-color: rgba(0,0,0,.05);">
                      <td><b>Leave Type</b></td>
                      <td><b>Start Date</b></td>
                      <td><b>End Date</b></td>
                    </tr>
                    <tr>
                      <td width="33.33%">${data.LeaveTypeDescription}</td>
                      <td width="33.33%">${data.StartDateDisplay}</td>
                      <td width="33.33%">${data.EndDateDisplay}</td>
                    </tr>
                    <tr style="background-color: rgba(0,0,0,.05);">
                      <td colspan="3"><b>Number of Leave Days</b></td>
                    </tr>
                    <tr>
                      <td colspan="3">${data.NumberOfLeaveDays}</td>
                    </tr>
                    <tr style="background-color: rgba(0,0,0,.05);">
                      <td colspan="3"><b>Remarks</b></td>
                    </tr>
                    <tr>
                      <td colspan="3">${data.Remarks}</td>
                    </tr>
                    <tr style="background-color: rgba(0,0,0,.05);">
                      <td colspan="3"><b>Status</b></td>
                    </tr>
                    <tr>
                      <td colspan="3">${data.Status}</td>
                    </tr>
                </table>
            </body>
            </html>`;
            let w = window.open("", "PRINT", "height=700,width=1300");
            w.document.write(html);
            //for i.e
            w.document.close();
            w.focus();
            //for the rest
            window.setTimeout(() => {
              w.print();
              w.close();
            }, 500);
            //w.print();
            //w.close();
          } else {
            this.$swal(response.data.message, { icon: "error" });
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    //populate userId and token
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    this.userId = data.Id;
    this.token = data.Token;
    //populate leave type
    API.admin
      .getLeaveTypesByEmployeeId({
        Token: data.Token,
        Id: data.Id,
      })
      .then((response) => {
        if (response.data.message == "Unauthorized access") {
          this.$swal("Multiple login detected. You have been logged out", {
            icon: "error",
          });
          this.$router.replace({ name: "login" });
          return;
        }

        this.allLeaveTypes = response.data.allLeaveTypes;
        this.leaveTypes = response.data.allLeaveTypes.filter(
          (c) => c.IsCustom == false
        );
        this.leaveTitles = response.data.userLeaveTitles;
      })
      .catch((error) => {
        this.$swal(error.response.data.Message, { icon: "error" });
      });
    //get all leave application list
    this.getLeaveApplicationList();
  },
};
</script>

<style scoped>
.row label {
  margin-bottom: 1px !important;
}
</style>