<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark"
      style="padding-left: 2rem; padding-right: 2rem"
    >
      <div class="d-flex flex-grow-1">
        <span class="w-100 d-lg-none d-block"
          ><!-- hidden spacer to center brand on mobile --></span
        >
        <a class="navbar-brand" href="#" @click.prevent>
          <span
            style="
              letter-spacing: 0.08rem;
              font-size: 25px;
              font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              color: #d32300;
            "
            >career</span
          >
          <span
            style="
              letter-spacing: 0.08rem;
              font-size: 25px;
              font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              color: #68b04d;
            "
            >library</span
          >
        </a>
        <div class="w-100 text-right">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#menu"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse flex-grow-1 text-right" id="menu">
        <ul class="navbar-nav ml-auto flex-nowrap">
          <router-link
            :to="{ name: 'account' }"
            tag="li"
            :active-class="
              $route.path.includes('/account') ? 'nav-item active' : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">My Account</a>
          </router-link>
          <router-link
            :to="{ name: 'leaveForm' }"
            tag="li"
            :active-class="
              $route.path.includes('/leaveform') ? 'nav-item active' : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">Leave Application Form</a>
          </router-link>
          <router-link
            :to="{ name: 'publicHoliday' }"
            tag="li"
            :active-class="
              $route.path.includes('/publicholiday') ? 'nav-item active' : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">Singapore Public Holidays</a>
          </router-link>
          <router-link
            :to="{ name: 'myLeaveApplicationIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/myleaveapplication')
                ? 'nav-item active'
                : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">My Leave Applications</a>
          </router-link>
          <router-link
            :to="{ name: 'myLeaveEntitlement' }"
            tag="li"
            :active-class="
              $route.path.includes('/myleaveentitlement')
                ? 'nav-item active'
                : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">My Leave Entitlements</a>
          </router-link>
          <router-link
            :to="{ name: 'myDocument' }"
            tag="li"
            :active-class="
              $route.path.includes('/mydocument') ? 'nav-item active' : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">My Documents</a>
          </router-link>
          <router-link
            v-if="hasEmployeeLeave"
            :to="{ name: 'employeeLeaveApplicationIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/employeeleaveapplication')
                ? 'nav-item active'
                : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">Employees' Leave Applications</a>
          </router-link>
          <router-link
            v-if="hasEmployeeLeave"
            :to="{ name: 'employeeLeaveEntitlementIndex' }"
            tag="li"
            :active-class="
              $route.path.includes('/employeeleaveentitlement')
                ? 'nav-item active'
                : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">Employees' Leave Entitlements</a>
          </router-link>
          <router-link
            v-if="hasEmployeeDocument"
            :to="{ name: 'employeeDocument' }"
            tag="li"
            :active-class="
              $route.path.includes('/employeedocument') ? 'nav-item active' : ''
            "
            class="employee-mobile-link"
          >
            <a class="nav-link">Employees' Documents</a>
          </router-link>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-user-circle"></i>&nbsp;{{ name }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <router-link
                class="dropdown-item"
                :to="{ name: 'changePassword' }"
                >Change Password</router-link
              >
              <a class="dropdown-item" href="#" @click.prevent="logout">
                Logout&nbsp;&nbsp;<i class="fas fa-sign-out-alt"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="row employee-row">
      <div
        class="col-md-3 mt-2 employee-side-menu"
        style="max-width: fit-content; padding-right: 0.5rem"
      >
        <nav class="navbar navbar-dark bg-dark sidebar">
          <ul class="navbar-nav">
            <router-link
              :to="{ name: 'account' }"
              tag="li"
              :active-class="
                $route.path.includes('/account') ? 'nav-item active' : ''
              "
            >
              <a class="nav-link">My Account</a>
            </router-link>
            <router-link
              :to="{ name: 'leaveForm' }"
              tag="li"
              :active-class="
                $route.path.includes('/leaveform') ? 'nav-item active' : ''
              "
            >
              <a class="nav-link">Leave Application Form</a>
            </router-link>
            <router-link
              :to="{ name: 'publicHoliday' }"
              tag="li"
              :active-class="
                $route.path.includes('/publicholiday') ? 'nav-item active' : ''
              "
            >
              <a class="nav-link">Singapore Public Holidays</a>
            </router-link>
            <router-link
              :to="{ name: 'myLeaveApplicationIndex' }"
              tag="li"
              :active-class="
                $route.path.includes('/myleaveapplication')
                  ? 'nav-item active'
                  : ''
              "
            >
              <a class="nav-link">My Leave Applications</a>
            </router-link>
            <router-link
              :to="{ name: 'myLeaveEntitlement' }"
              tag="li"
              :active-class="
                $route.path.includes('/myleaveentitlement')
                  ? 'nav-item active'
                  : ''
              "
            >
              <a class="nav-link">My Leave Entitlements</a>
            </router-link>
            <router-link
              :to="{ name: 'myDocument' }"
              tag="li"
              :active-class="
                $route.path.includes('/mydocument') ? 'nav-item active' : ''
              "
            >
              <a class="nav-link">My Documents</a>
            </router-link>
            <router-link
              v-if="hasEmployeeLeave"
              :to="{ name: 'employeeLeaveApplicationIndex' }"
              tag="li"
              :active-class="
                $route.path.includes('/employeeleaveapplication')
                  ? 'nav-item active'
                  : ''
              "
            >
              <a class="nav-link">Employees' Leave Applications</a>
            </router-link>
            <router-link
              v-if="hasEmployeeLeave"
              :to="{ name: 'employeeLeaveEntitlementIndex' }"
              tag="li"
              :active-class="
                $route.path.includes('/employeeleaveentitlement')
                  ? 'nav-item active'
                  : ''
              "
            >
              <a class="nav-link">Employees' Leave Entitlements</a>
            </router-link>
            <router-link
              v-if="hasEmployeeDocument"
              :to="{ name: 'employeeDocument' }"
              tag="li"
              :active-class="
                $route.path.includes('/employeedocument')
                  ? 'nav-item active'
                  : ''
              "
            >
              <a class="nav-link">Employees' Documents</a>
            </router-link>
          </ul>
        </nav>
      </div>
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      name: "",
      hasEmployeeLeave: false,
      hasEmployeeDocument: false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("employeelmstoken");
      this.$router.replace({ name: "login" });
    },
  },
  created() {
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    if (!data) return;
    this.name = data.Name;
    //check if user has employee leave application and document
    let param = {
      Token: data.Token,
      Email: data.Email,
      Id: data.Id,
    };
    API.employee
      .hasEmployeeLeaveDocument(param)
      .then((response) => {
        if (response.data.message == "Unauthorized access") {
          this.$swal("Multiple login detected. You have been logged out", {
            icon: "error",
          });
          this.$router.replace({ name: "login" });
          return;
        }

        if (!response.data.success) {
          this.$swal(response.data.message, {
            icon: "error",
          });
        } else {
          this.hasEmployeeLeave = response.data.hasEmployeeLeave;
          this.hasEmployeeDocument = response.data.hasEmployeeDocument;
        }
      })
      .catch((error) => {
        this.$swal(error.response.data.Message, { icon: "error" });
      });
  },
};
</script>

<style scoped>
.dropdown-menu {
  font-size: 0.7rem;
  min-width: 5rem;
  left: unset;
  right: -5px;
}

.dropdown-item {
  padding: 0.25rem 1rem;
}

.nav-link:hover {
  color: white !important;
}

.sidebar {
  /* border-radius */
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
</style>