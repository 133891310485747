<template>
  <div>
    <h4>Public Holidays</h4>
    <div class="page-content mt-4">
      <form @submit.prevent="add">
        <div class="form-group row">
          <label class="col-md-2 col-form-label"
            >Public Holiday<span class="text-danger">*</span></label
          >
          <div class="col-md-5">
            <input
              v-model="formAdd.Name"
              type="text"
              placeholder=""
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label"
            >Date<span class="text-danger">*</span></label
          >
          <div class="col-md-5">
            <input
              v-model="formAdd.DateFormStr"
              type="date"
              placeholder=""
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Remarks</label>
          <div class="col-md-5">
            <textarea
              v-model="formAdd.Remarks"
              type="text"
              placeholder=""
              class="form-control"
            >
            </textarea>
          </div>
          <div class="col-md-3 mt-4">
            <button type="submit" class="btn btn-primary mr-3">Add</button>
            <button @click="$router.go(-1)" class="btn btn-secondary">
              <i class="fa fa-reply"></i>&nbsp;Back
            </button>
          </div>
        </div>
      </form>
      <form id="updateForm" @submit.prevent="update"></form>
      <select
        v-model="year"
        class="form-control"
        style="width: fit-content; font-weight: bold"
      >
        <option v-for="item in years" :value="item" :key="item">
          {{ item }}
        </option>
      </select>
      <div class="table-responsive mt-4">
        <table class="table table-bordered w-100">
          <thead class="thead-light">
            <tr>
              <th style="width: 20%">Public Holiday</th>
              <th style="width: 15%">Date</th>
              <th style="width: 10%">Day</th>
              <th style="width: 35%">Remarks</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in displayedPublicHolidays" :key="item.Id">
              <template v-if="formUpdate.Id !== item.Id">
                <td>{{ item.Name }}</td>
                <td>{{ item.DateStr }}</td>
                <td>{{ item.Day }}</td>
                <td>{{ item.Remarks }}</td>
                <td>
                  <a href="javascript:void(0)" @click.prevent="edit(item)"
                    >Edit</a
                  >
                  <span class="ml-1 mr-1">|</span>
                  <a href="javascript:void(0)" @click.prevent="remove(item.Id)"
                    >Delete</a
                  >
                </td>
              </template>
              <template v-else>
                <td class="table-info align-middle">
                  <input
                    form="updateForm"
                    type="text"
                    class="form-control form-control-sm"
                    v-model="formUpdate.Name"
                    required
                  />
                </td>
                <td class="table-info align-middle">
                  <input
                    form="updateForm"
                    type="date"
                    class="form-control form-control-sm"
                    v-model="formUpdate.DateFormStr"
                    required
                  />
                </td>
                <td class="table-info align-middle"></td>
                <td class="table-info align-middle">
                  <textarea
                    form="updateForm"
                    type="text"
                    class="form-control form-control-sm"
                    v-model="formUpdate.Remarks"
                  ></textarea>
                </td>
                <td class="table-info align-middle">
                  <button
                    type="submit"
                    class="btn btn-sm btn-link p-0"
                    form="updateForm"
                  >
                    Update
                  </button>
                  <span class="ml-1 mr-1">|</span>
                  <button
                    type="button"
                    class="btn btn-sm btn-link p-0"
                    @click="cancel"
                  >
                    Cancel
                  </button>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      publicHolidays: [],
      year: "",
      formUpdate: {
        Id: "",
        Name: "",
        DateFormStr: "",
        Remarks: "",
      },
      formAdd: {
        Name: "",
        DateFormStr: "",
        Remarks: "",
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
    years() {
      let filteredYears = [...new Set(this.publicHolidays.map((c) => c.Year))];
      return filteredYears;
    },
    displayedPublicHolidays() {
      return this.publicHolidays.filter((c) => c.Year == this.year);
    },
  },
  methods: {
    update() {
      let param = {
        Token: this.token,
        Id: this.formUpdate.Id,
        Name: this.formUpdate.Name,
        DateFormStr: this.formUpdate.DateFormStr,
        Remarks: this.formUpdate.Remarks,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to update the public holiday?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willUpdate) => {
        if (willUpdate) {
          API.admin
            .updatePublicHoliday(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.cancel();
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    edit(payload) {
      this.reset();
      this.formUpdate.Id = payload.Id;
      this.formUpdate.Name = payload.Name;
      this.formUpdate.DateFormStr = payload.DateUpdateStr;
      this.formUpdate.Remarks = payload.Remarks;
    },
    cancel() {
      this.formUpdate = {
        Id: "",
        Name: "",
        DateFormStr: "",
        Remarks: "",
      };
      this.getPublicHolidays();
    },
    reset() {
      this.formAdd = {
        Name: "",
        DateFormStr: "",
        Remarks: "",
      };
      this.formUpdate = {
        Id: "",
        Name: "",
        DateFormStr: "",
        Remarks: "",
      };
      this.getPublicHolidays();
    },
    remove(Id) {
      let param = {
        Token: this.token,
        Id: Id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the public holiday?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          API.admin
            .deletePublicHoliday(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.cancel();
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    add() {
      let param = {
        Token: this.token,
        Name: this.formAdd.Name,
        DateFormStr: this.formAdd.DateFormStr,
        Remarks: this.formAdd.Remarks,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to add the public holiday?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          API.admin
            .addPublicHoliday(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.reset();
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getPublicHolidays() {
      API.admin
        .getPublicHolidayList({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }
          if (response.data.success) {
            let data = response.data.data;
            this.publicHolidays = data;
            this.year = data[data.length - 1].Year;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.getPublicHolidays();
  },
};
</script>