<template>
  <div>
    <h4>My Profile</h4>
    <form @submit.prevent="update" class="mt-4 page-content">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Username</label>
        <div class="col-md-5">
          <input
            v-model="username"
            type="text"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Email</label>
        <div class="col-md-5">
          <input
            v-model="email"
            type="email"
            placeholder=""
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-7 text-right">
          <button type="submit" class="btn btn-primary">Update</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../http-common.js";
export default {
  data() {
    return {
      username: "",
      email: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
    userId() {
      return this.$store.state.userDetail.Id;
    },
  },
  methods: {
    populateUserDetail() {
      API.admin
        .getUserDetail({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.$store.state.userDetail = response.data;
          this.username = response.data.Username;
          this.email = response.data.Email;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    update() {
      let param = {
        Token: this.token,
        Id: this.userId,
        Username: this.username,
        Email: this.email,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to update your profile?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          API.admin
            .updateProfile(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              this.populateUserDetail();
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
  },
  created() {
    this.populateUserDetail();
  },
};
</script>

<style scoped>
.alert {
  width: 650px;
  padding: 5px;
  font-size: 0.6rem;
}
</style>