import Vue from 'vue'
import VueRouter from 'vue-router'
import API from './http-common.js'
import config from './config'

//admin
import AdminLogin from './components/Admin/Login.vue'
import AdminForgotPassword from './components/Admin/ForgotPassword.vue'
import AdminResetPassword from './components/Admin/ResetPassword.vue'
import AdminChangePassword from './components/Admin/ChangePassword.vue'
import AdminPublicHoliday from './components/Admin/PublicHoliday.vue'
import AdminProfile from './components/Admin/Profile.vue'
import AdminLayout from './components/Admin/Layout.vue'
import AdminOrganisationIndex from './components/Admin/Organisation/Index.vue'
import AdminOrganisationCreate from './components/Admin/Organisation/Create.vue'
import AdminOrganisationEdit from './components/Admin/Organisation/Edit.vue'
import AdminOrganisationShow from './components/Admin/Organisation/Show.vue'

import AdminEmployeeIndex from './components/Admin/Employee/Index.vue'
import AdminEmployeeCreate from './components/Admin/Employee/Create.vue'
import AdminEmployeeEdit from './components/Admin/Employee/Edit.vue'
import AdminEmployeeShow from './components/Admin/Employee/Show.vue'
import AdminEmployeeLeaveEntitlement from './components/Admin/Employee/LeaveEntitlement.vue'
import AdminEmployeeDocument from './components/Admin/Employee/Document.vue'

import AdminLeaveApplicationIndex from './components/Admin/LeaveApplication/Index.vue'
import AdminLeaveApplicationCreate from './components/Admin/LeaveApplication/Create.vue'
import AdminLeaveApplicationEdit from './components/Admin/LeaveApplication/Edit.vue'
import AdminLeaveApplicationShow from './components/Admin/LeaveApplication/Show.vue'

import AdminDocumentIndex from './components/Admin/Document/Index.vue'

//employee
import Login from './components/Employee/Login.vue'
import ForgotPassword from './components/Employee/ForgotPassword.vue'
import ResetPassword from './components/Employee/ResetPassword.vue'
import Layout from './components/Employee/Layout.vue'
import Account from './components/Employee/Account.vue'
import ChangePassword from './components/Employee/ChangePassword.vue'
import LeaveForm from './components/Employee/LeaveForm.vue'
import PublicHoliday from './components/Employee/PublicHoliday.vue'
import MyLeaveApplicationIndex from './components/Employee/MyLeaveApplication/Index.vue'
import MyLeaveApplicationEdit from './components/Employee/MyLeaveApplication/Edit.vue'
import MyLeaveEntitlement from './components/Employee/MyLeaveEntitlement.vue'
import MyDocument from './components/Employee/MyDocument.vue'
import EmployeeLeaveApplicationIndex from './components/Employee/EmployeeLeaveApplication/Index.vue'
import EmployeeLeaveApplicationApprovalRequest from './components/Employee/EmployeeLeaveApplication/ApprovalRequest.vue'
import EmployeeLeaveEntitlementIndex from './components/Employee/EmployeeLeaveEntitlement/Index.vue'
import EmployeeLeaveEntitlementShow from './components/Employee/EmployeeLeaveEntitlement/Show.vue'
import EmployeeDocument from './components/Employee/EmployeeDocument.vue'

Vue.use(VueRouter)
let appPath = config.APP_ROOT_PATH;

const routes = [
  //admin
  { path: appPath + 'admin/login', name: 'adminLogin', component: AdminLogin },
  { path: appPath + 'admin/forgot', name: 'adminForgotPassword', component: AdminForgotPassword },
  { path: appPath + 'admin/:token/reset', name: 'adminResetPassword', component: AdminResetPassword },
  {
    path: appPath + 'admin', component: AdminLayout, children: [
      { path: '', redirect: { name: 'adminOrganisationIndex' } },//default  
      { path: 'organisation', name: 'adminOrganisationIndex', component: AdminOrganisationIndex },
      { path: 'organisation/create', name: 'adminOrganisationCreate', component: AdminOrganisationCreate },
      { path: 'organisation/:id/edit', name: 'adminOrganisationEdit', component: AdminOrganisationEdit },
      { path: 'organisation/:id/show', name: 'adminOrganisationShow', component: AdminOrganisationShow },

      { path: 'employee', name: 'adminEmployeeIndex', component: AdminEmployeeIndex },
      { path: 'employee/create', name: 'adminEmployeeCreate', component: AdminEmployeeCreate },
      { path: 'employee/:id/edit', name: 'adminEmployeeEdit', component: AdminEmployeeEdit },
      { path: 'employee/:id/show', name: 'adminEmployeeShow', component: AdminEmployeeShow },
      { path: 'employee/:id/leaveentitlement', name: 'adminEmployeeLeaveEntitlement', component: AdminEmployeeLeaveEntitlement },
      { path: 'employee/:id/document', name: 'adminEmployeeDocument', component: AdminEmployeeDocument },

      { path: 'leaveapplication', name: 'adminLeaveApplicationIndex', component: AdminLeaveApplicationIndex },
      { path: 'leaveapplication/create', name: 'adminLeaveApplicationCreate', component: AdminLeaveApplicationCreate },
      { path: 'leaveapplication/:id/edit', name: 'adminLeaveApplicationEdit', component: AdminLeaveApplicationEdit },
      { path: 'leaveapplication/:id/show', name: 'adminLeaveApplicationShow', component: AdminLeaveApplicationShow },

      { path: 'document', name: 'adminDocumentIndex', component: AdminDocumentIndex },

      { path: 'changepassword', name: 'adminChangePassword', component: AdminChangePassword },
      { path: 'profile', name: 'adminProfile', component: AdminProfile },
      { path: 'publicholiday', name: 'adminPublicHoliday', component: AdminPublicHoliday }
    ], beforeEnter(to, from, next) {
      if (from.name == 'adminLogin') return next();
      let token = localStorage.getItem('adminlmstoken')
      if (!token) return next({ name: 'adminLogin' })
      API.common.verifyToken({ Token: token }).then(response => {
        if (response.data == false) {
          localStorage.removeItem('adminlmstoken')
          return next({ name: 'adminLogin' })
        } else {
          return next()
        }
      }).catch(error => {
        console.log(error)
        return next({ name: 'adminLogin' })
      })
    }
  },
  //employee
  { path: appPath + 'login', name: 'login', component: Login },
  { path: appPath + 'forgot', name: 'forgotPassword', component: ForgotPassword },
  { path: appPath + ':token/reset', name: 'resetPassword', component: ResetPassword },
  {
    path: appPath, component: Layout, children: [
      { path: '', redirect: { name: 'account' } },
      { path: 'account', name: 'account', component: Account },
      { path: 'changepassword', name: 'changePassword', component: ChangePassword },
      { path: 'leaveform', name: 'leaveForm', component: LeaveForm },
      { path: 'publicholiday', name: 'publicHoliday', component: PublicHoliday },
      { path: 'myleaveapplication', name: 'myLeaveApplicationIndex', component: MyLeaveApplicationIndex },
      { path: 'myleaveapplication/:id/edit', name: 'myLeaveApplicationEdit', component: MyLeaveApplicationEdit },
      { path: 'myleaveentitlement', name: 'myLeaveEntitlement', component: MyLeaveEntitlement },
      { path: 'mydocument', name: 'myDocument', component: MyDocument },
      { path: 'employeeleaveapplication', name: 'employeeLeaveApplicationIndex', component: EmployeeLeaveApplicationIndex },
      { path: 'employeeleaveapplication/:id/approvalrequest', name: 'employeeLeaveApplicationApprovalRequest', component: EmployeeLeaveApplicationApprovalRequest },
      { path: 'employeeleaveentitlement', name: 'employeeLeaveEntitlementIndex', component: EmployeeLeaveEntitlementIndex },
      { path: 'employeeleaveentitlement/:id/show', name: 'employeeLeaveEntitlementShow', component: EmployeeLeaveEntitlementShow },
      { path: 'employeedocument', name: 'employeeDocument', component: EmployeeDocument },
    ], beforeEnter(to, from, next) {
      if (from.name == 'login' || to.name == 'employeeLeaveApplicationApprovalRequest') return next();
      let data = JSON.parse(localStorage.getItem('employeelmstoken'))
      if (!data) return next({ name: 'login' })
      API.common.verifyToken({ Token: data.Token }).then(response => {
        if (response.data == false) {
          localStorage.removeItem('employeelmstoken')
          return next({ name: 'login' })
        } else {
          return next()
        }
      }).catch(error => {
        console.log(error)
        return next({ name: 'login' })
      })
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router