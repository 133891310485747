<template>
  <div>
    <h4>Add Organisation</h4>
    <form class="mt-4 mb-5 page-content" @submit.prevent="addOrganisation">
      <!-- Details -->
      <div class="form-group">
        <h5>Details</h5>
        <hr class="my-0" />
      </div>
      <div class="form-row">
        <div class="form-group col-md-5">
          <label>Logo 1</label>
          <img
            :src="logo1 ? logo1 : '/assets/no-preview.jpg'"
            class="ml-1"
            style="width: 25%"
            id="previewLogo1"
          />
          <a
            v-if="logo1"
            href="javascript:void(0)"
            id="removeLogo1"
            title="Remove Logo 1"
            @click.prevent="removeLogo1"
            ><i class="fa fa-trash-alt" style="color: grey"></i
          ></a>
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5">
          <label>Logo 2</label>
          <img
            :src="logo2 ? logo2 : '/assets/no-preview.jpg'"
            class="ml-1"
            style="width: 25%"
            id="previewLogo2"
          />
          <a
            v-if="logo2"
            href="javascript:void(0)"
            id="removeLogo2"
            title="Remove Logo 2"
            @click.prevent="removeLogo2"
            ><i class="fa fa-trash-alt" style="color: grey"></i
          ></a>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-5">
          <input
            type="file"
            class="custom-file-input form-control-file"
            accept="image/*"
            id="customFileLogo1"
            @change="onLogo1Selected($event)"
          />
          <label
            id="logo1Placeholder"
            class="custom-file-label ml-1"
            for="customFileLogo1"
            >{{ fileNameLogo1 }}</label
          >
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5">
          <input
            name="logo2"
            type="file"
            class="custom-file-input form-control-file"
            accept="image/*"
            id="customFileLogo2"
            @change="onLogo2Selected($event)"
          />
          <label
            id="logo2Placeholder"
            class="custom-file-label mr-1"
            for="customFileLogo2"
            >{{ fileNameLogo2 }}</label
          >
        </div>
      </div>
      <div class="form-group">
        <label>Code<span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="code"
          class="form-control"
          placeholder="Input Company Code Here"
          required
        />
      </div>
      <div class="form-group">
        <label>Name<span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="name"
          class="form-control"
          placeholder="Input Company Name Here"
          required
        />
      </div>
      <div class="form-group">
        <label>Contact Person</label>
        <input
          type="text"
          v-model="contactPerson"
          class="form-control"
          placeholder="Input Contact Person Here"
        />
      </div>
      <div class="form-group">
        <label>Contact Number</label>
        <input
          type="text"
          v-model="contactNumber"
          class="form-control"
          placeholder="Input Contact Number Here"
        />
      </div>
      <!-- Address -->
      <div class="form-group mt-4">
        <h5>Address</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label>Address 1<span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="address1"
          class="form-control"
          placeholder="Input Address 1 Here"
          required
        />
      </div>
      <div class="form-group">
        <label>Address 2 - (Optional)</label>
        <input
          type="text"
          v-model="address2"
          class="form-control"
          placeholder="Input Address 2 Here (Optional)"
        />
      </div>
      <div class="form-group">
        <label>Address 3 - (Optional)</label>
        <input
          type="text"
          v-model="address3"
          class="form-control"
          placeholder="Input Address 3 Here (Optional)"
        />
      </div>
      <!-- Government Related -->
      <div class="form-group mt-4">
        <h5>Government Related</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label
          >UEN(Unique Entity Number) Account 1<span class="text-danger"
            >*</span
          ></label
        >
        <input
          type="text"
          v-model="uen1"
          class="form-control"
          placeholder="Input UEN Account 1"
          required
        />
      </div>
      <div class="form-group">
        <label>UEN(Unique Entity Number) Account 2 - (Optional)</label>
        <input
          type="text"
          v-model="uen2"
          class="form-control"
          placeholder="Input UEN Account 2 (Optional)"
        />
      </div>
      <!-- Leave -->
      <div class="form-group mt-4">
        <h5>Leave</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label
          >Leave Entitlement Organisation<span class="text-danger"
            >*</span
          ></label
        >
        <select v-model="leaveEntitlementTypeId" class="form-control" required>
          <option value selected>Select Leave Entitlement Organisation</option>
          <option
            v-for="item in leaveEntitlementTypes"
            :key="item.Id"
            :value="item.Id"
          >
            {{ item.Description }}
          </option>
        </select>
      </div>
      <!-- Others -->
      <div class="form-group mt-4">
        <h5>Others</h5>
        <hr class="my-0" />
      </div>
      <div class="form-group">
        <label>Status</label>
        <select v-model="active" class="form-control" required>
          <option value="true" selected>Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
      <div class="form-group">
        <label>Remarks - (Optional)</label>
        <textarea class="form-control" v-model="remarks"></textarea>
      </div>
      <div class="text-center">
        <button class="btn btn-primary" type="submit">Add</button>&nbsp;&nbsp;
        <router-link
          class="btn btn-danger"
          :to="{ name: 'adminOrganisationIndex' }"
          >Cancel</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      logo1: null,
      logo2: null,
      code: "",
      name: "",
      contactPerson: "",
      contactNumber: "",
      address1: "",
      address2: "",
      address3: "",
      uen1: "",
      uen2: "",
      leaveEntitlementTypeId: "",
      active: true,
      remarks: "",
      leaveEntitlementTypes: [],
      fileNameLogo1: "Upload Company Logo 1",
      fileNameLogo2: "Upload Company Logo 2",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  methods: {
    removeLogo1() {
      this.logo1 = null;
      this.fileNameLogo1 = "Upload Company Logo 1";
    },
    removeLogo2() {
      this.logo2 = null;
      this.fileNameLogo2 = "Upload Company Logo 2";
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    onLogo1Selected(event) {
      const file = event.target.files[0];
      this.fileNameLogo1 = file.name;
      let self = this;
      let promise = self.getBase64(file);
      promise.then(function (result) {
        self.logo1 = result;
      });
    },
    onLogo2Selected(event) {
      const file = event.target.files[0];
      this.fileNameLogo2 = file.name;
      let self = this;
      let promise = self.getBase64(file);
      promise.then(function (result) {
        self.logo2 = result;
      });
    },
    addOrganisation() {
      let param = {
        Token: this.token,
        Logo1: this.logo1,
        Logo2: this.logo2,
        Code: this.code,
        Name: this.name,
        ContactPerson: this.contactPerson,
        ContactNumber: this.contactNumber,
        Address1: this.address1,
        Address2: this.address2,
        Address3: this.address3,
        UEN1: this.uen1,
        UEN2: this.uen2,
        LeaveEntitlementTypeId: this.leaveEntitlementTypeId,
        Active: this.active,
        Remarks: this.remarks,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to add the organisation?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          API.admin
            .addOrganisation(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success)
                this.$router.replace({ name: "adminOrganisationIndex" });
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getLeaveEntitlementTypes() {
      API.common
        .getLeaveEntitlementTypes({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.leaveEntitlementTypes = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.getLeaveEntitlementTypes();
  },
};
</script>