<template>
  <div>
    <h4>Leave Applications</h4>
    <div class="mt-3 page-content">
      <form @submit.prevent="search">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Employee Name</label>
            <input
              type="text"
              placeholder="Search Name Here"
              class="form-control"
              v-model="name"
            />
          </div>
          <div class="form-group col-md-2 fit-content">
            <label>Organisation (Filter)</label>
            <select class="form-control" v-model="organisationId">
              <option value>All</option>
              <option
                v-for="org in organisations"
                :value="org.Id"
                :key="org.Id"
              >
                {{ org.Name }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-3 fit-content">
            <label>Leave Type</label>
            <select class="form-control" v-model="leaveTypeDesc">
              <option value>All</option>
              <option
                v-for="(item, i) in leaveTypes"
                :value="item.Description"
                :key="i"
              >
                {{ item.Description }}
              </option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div
            class="form-group col-md-2 fit-content"
            v-if="leaveTypeDesc == 'Others'"
          >
            <label>Title</label>
            <select class="form-control" v-model="leaveTitle">
              <option value selected>All</option>
              <option
                v-for="(title, i) in leaveTitles"
                :value="title.Description"
                :key="i"
              >
                {{ title.Description }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2 fit-content">
            <label>Start Date</label>
            <input type="date" class="form-control" v-model="fromDate" />
          </div>
          <div class="form-group col-md-2 fit-content">
            <label>End Date</label>
            <input type="date" class="form-control" v-model="toDate" />
          </div>
          <div class="form-group col-md-1">
            <button
              type="submit"
              class="btn btn-primary form-control"
              style="margin-top: 1.65rem"
            >
              Go
            </button>
          </div>
        </div>
      </form>
      <router-link :to="{ name: 'adminLeaveApplicationCreate' }"
        >+ Add Leave Application</router-link
      >
      <div class="table-responsive" v-if="leaveApplications.length">
        <table class="table table-bordered mt-3" style="width: 100%">
          <thead class="thead-light">
            <tr>
              <th>Organisation</th>
              <th>Employee</th>
              <th>Leave Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Number of Leave Days</th>
              <th>Created Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in leaveApplications" :key="item.Id">
              <td class="align-middle">{{ item.OrganisationName }}</td>
              <td class="align-middle">{{ item.EmployeeName }}</td>
              <td class="align-middle">{{ item.LeaveTypeDescription }}</td>
              <td class="align-middle">{{ item.StartDateDisplay }}</td>
              <td class="align-middle">{{ item.EndDateDisplay }}</td>
              <td class="align-middle">{{ item.NumberOfLeaveDays }}</td>
              <td class="align-middle">{{ item.CreatedDateStr }}</td>
              <td class="align-middle">{{ item.Status }}</td>
              <td class="align-middle">
                <router-link
                  :to="{
                    name: 'adminLeaveApplicationShow',
                    params: { id: item.Id },
                  }"
                  >View</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <router-link
                  :to="{
                    name: 'adminLeaveApplicationEdit',
                    params: { id: item.Id },
                  }"
                  >Edit</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <a
                  href="javascript:void(0)"
                  @click.prevent="deleteLeaveApplication(item.Id)"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="leaveApplications.length" class="display-page">
        Displaying
        <select
          class="custom-select custom-select-sm"
          v-model="paging.SizePerPage"
          style="width: 65px; margin-right: 10px; margin-left: 5px"
        >
          <option
            v-for="item in paging.Sizes"
            v-bind:key="item"
            v-bind:value="item"
          >
            {{ item }}
          </option>
        </select>
        <button
          v-if="paging.CurrentPage > 1"
          v-on:click="first()"
          class="btn btn-sm btn-link"
        >
          First
        </button>
        <button
          v-on:click="prev()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage > 1"
        >
          Prev
        </button>
        <span style="padding-left: 5px; padding-right: 5px"
          >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
        >
        <button
          v-on:click="next()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage < paging.TotalPage"
        >
          Next
        </button>
        <button
          v-if="paging.CurrentPage < paging.TotalPage"
          v-on:click="last()"
          class="btn btn-sm btn-link"
        >
          Last
        </button>
      </div>
      <div v-if="!leaveApplications.length" class="text-center">
        No records available
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      leaveApplications: [],
      name: "",
      organisationId: "",
      organisations: [],
      leaveTypeDesc: "",
      leaveTypes: [],
      leaveTitle: "",
      leaveTitles: [],
      allLeaveTypes: [],
      fromDate: "",
      toDate: "",
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
  },
  methods: {
    first() {
      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getLeaveApplicationList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getLeaveApplicationList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getLeaveApplicationList();
    },
    search() {
      //set the state from filter
      this.$store.state.leaveApplicationFilter.employeeName = this.name;
      this.$store.state.leaveApplicationFilter.organisationId =
        this.organisationId;
      this.$store.state.leaveApplicationFilter.leaveTypeDesc =
        this.leaveTypeDesc;
      this.$store.state.leaveApplicationFilter.leaveTitle = this.leaveTitle;

      this.paging.CurrentPage = 1;
      this.getLeaveApplicationList();
    },
    deleteLeaveApplication(leaveApplicationId) {
      let param = {
        Token: this.token,
        Id: leaveApplicationId,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.state.isLoading = true;
          API.admin
            .deleteLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.paging.CurrentPage = 1;
                this.getLeaveApplicationList();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getLeaveApplicationList() {
      //date validation
      if (this.fromDate && this.toDate) {
        let fDate = new Date(this.fromDate);
        let tDate = new Date(this.toDate);
        if (fDate > tDate) {
          this.$swal("Start Date cannot be greater than End Date", {
            icon: "error",
          });
          return;
        }
      }
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.leaveTypeDesc == "Others"
            ? this.leaveTitle
            : this.leaveTypeDesc)
      );
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        Name: this.name,
        OrganisationId: this.organisationId,
        LeaveTypeId: leaveType ? leaveType.Id : "",
        IsOthersSelected: this.leaveTypeDesc == "Others",
        FromDate: this.fromDate,
        ToDate: this.toDate,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getLeaveApplicationList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.$store.state.isLoading = false;
            this.leaveApplications = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getOrganisationListForDropdown() {
      API.admin
        .getOrganisationListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.organisations = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getLeaveTypeListForDropdown() {
      API.admin
        .getLeaveTypeListForDropdown({ Token: this.token })
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          this.allLeaveTypes = response.data.allLeaveTypes;
          this.leaveTypes = response.data.allLeaveTypes.filter(
            (c) =>
              c.Description != API.constant.leaveType.Annual &&
              c.IsCustom == false
          );
          this.leaveTitles = response.data.allLeaveTypes.filter(
            (c) => c.IsCustom == true
          );
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    //populate the filter from state
    let leaveApplicationFilter = this.$store.state.leaveApplicationFilter;
    this.name = leaveApplicationFilter.employeeName;
    this.organisationId = leaveApplicationFilter.organisationId;
    this.leaveTypeDesc = leaveApplicationFilter.leaveTypeDesc;
    this.leaveTitle = leaveApplicationFilter.leaveTitle;

    this.getLeaveTypeListForDropdown();
    this.getOrganisationListForDropdown();
    setTimeout(() => {
      this.getLeaveApplicationList();
    }, 100);
  },
};
</script>

<style scoped>
.fit-content {
  max-width: fit-content;
}
@media only screen and (max-width: 768px) {
  /* for mobile phone */
  .fit-content {
    max-width: none;
  }
}
</style>