<template>
  <div>
    <h4>Organisation</h4>
    <div class="mt-3 page-content">
      <form @submit.prevent="search">
        <div class="form-row">
          <div class="form-group col-md-5">
            <label>Organisation Name</label>
            <input
              type="text"
              placeholder="Search Name Here"
              class="form-control"
              v-model="name"
            />
          </div>
          <div class="form-group ml-5"></div>
          <div class="form-group col-md-4">
            <label>Status (Filter)</label>
            <select class="form-control" v-model="active">
              <option value>All</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
          <div class="form-group ml-5"></div>
          <div class="form-group col-md-1">
            <button
              type="submit"
              class="btn btn-primary form-control"
              style="margin-top: 2rem"
            >
              Go
            </button>
          </div>
        </div>
      </form>
      <router-link :to="{ name: 'adminOrganisationCreate' }"
        >+ Add Organisation</router-link
      >
      <div class="table-responsive" v-if="organisations.length">
        <table class="table table-bordered mt-3" style="width: 100%">
          <thead class="thead-light">
            <tr>
              <th style="width: 7%">Logo 1</th>
              <th>Code</th>
              <th>Name</th>
              <th>Contact Person</th>
              <th>Contact Number</th>
              <th style="width: 15%">Leave Entitlement Organisation</th>
              <th>Status</th>
              <th style="width: 17%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="org in organisations" :key="org.Id">
              <td class="align-middle">
                <img v-if="org.Logo1" :src="org.Logo1" style="width: 60%" />
              </td>
              <td class="align-middle">{{ org.Code }}</td>
              <td class="align-middle">{{ org.Name }}</td>
              <td class="align-middle">{{ org.ContactPerson }}</td>
              <td class="align-middle">{{ org.ContactNumber }}</td>
              <td class="align-middle">{{ org.LeaveEntitlementType }}</td>
              <td class="align-middle">
                {{ org.Active ? "Active" : "Inactive" }}
              </td>
              <td class="align-middle">
                <router-link
                  :to="{
                    name: 'adminOrganisationShow',
                    params: { id: org.Id },
                  }"
                  >View</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <router-link
                  :to="{
                    name: 'adminOrganisationEdit',
                    params: { id: org.Id },
                  }"
                  >Edit</router-link
                >
                <span class="ml-1 mr-1">|</span>
                <a
                  href="javascript:void(0)"
                  @click.prevent="deleteOrganisation(org.Id)"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="organisations.length" class="display-page">
        Displaying
        <select
          class="custom-select custom-select-sm"
          v-model="paging.SizePerPage"
          style="width: 65px; margin-right: 10px; margin-left: 5px"
        >
          <option
            v-for="item in paging.Sizes"
            v-bind:key="item"
            v-bind:value="item"
          >
            {{ item }}
          </option>
        </select>
        <button
          v-if="paging.CurrentPage > 1"
          v-on:click="first()"
          class="btn btn-sm btn-link"
        >
          First
        </button>
        <button
          v-on:click="prev()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage > 1"
        >
          Prev
        </button>
        <span style="padding-left: 5px; padding-right: 5px"
          >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
        >
        <button
          v-on:click="next()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage < paging.TotalPage"
        >
          Next
        </button>
        <button
          v-if="paging.CurrentPage < paging.TotalPage"
          v-on:click="last()"
          class="btn btn-sm btn-link"
        >
          Last
        </button>
      </div>
      <div v-if="!organisations.length" class="text-center">
        No records available
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      name: "",
      active: "",
      organisations: [],
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getOrganisationList();
    },
  },
  methods: {
    first() {
      this.paging.CurrentPage = 1;
      this.getOrganisationList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getOrganisationList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getOrganisationList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getOrganisationList();
    },
    search() {
      //set the state from filter
      this.$store.state.organisationFilter.organisationName = this.name;
      this.$store.state.organisationFilter.status = this.active;
      this.paging.CurrentPage = 1;
      this.getOrganisationList();
    },
    deleteOrganisation(orgId) {
      let param = {
        Token: this.token,
        Id: orgId,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the organisation?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          API.admin
            .deleteOrganisation(param)
            .then((response) => {
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.paging.CurrentPage = 1;
                this.getOrganisationList();
              }
            })
            .catch((error) => {
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getOrganisationList() {
      let param = {
        Token: this.token,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
        Name: this.name,
        Active: this.active,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getOrganisationList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.organisations = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    //populate the filter from state
    let orgFilter = this.$store.state.organisationFilter;
    this.name = orgFilter.organisationName;
    this.active = orgFilter.status;
    this.getOrganisationList();
  },
};
</script>