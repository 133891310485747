<template>
  <div>
    <h4>Employee Leave Entitlement</h4>
    <div class="mt-3 page-content">
      <div class="row">
        <div class="col-sm-3">
          <label class="mr-2"><b>Employee ID: </b></label>{{ employeeId }}
        </div>
        <div class="col-sm-3">
          <label class="mr-2"><b>Name:</b></label
          >{{ employeeName }}
        </div>
        <div class="col-sm-3">
          <label class="mr-2"><b>Email:</b></label
          >{{ employeeEmail }}
        </div>
        <div class="col-sm-3">
          <label class="mr-2"><b>Organisation:</b></label
          >{{ organisationName }}
        </div>
      </div>
      <hr class="my-0" />
      <!-- Filter -->
      <form class="mt-3" @submit.prevent>
        <div class="form-row">
          <div class="form-group col-md-3 mr-md-3 fit-content">
            <label>Leave Type</label>
            <select v-model="leaveTypeDesc" class="form-control">
              <option value selected>All</option>
              <option
                v-for="(item, i) in leaveTypesDefault"
                :value="item.Description"
                :key="i"
              >
                {{ item.Description }}
              </option>
              <option value="Others" v-if="userLeaveTitles.length">
                Others
              </option>
            </select>
          </div>

          <div
            class="form-group col-md-3 mr-md-3 fit-content"
            v-if="leaveTypeDesc == 'Others'"
          >
            <label>Title</label>
            <select class="form-control" v-model="leaveTitle">
              <option value selected>All</option>
              <option
                v-for="(title, i) in userLeaveTitles"
                :value="title.Description"
                :key="i"
              >
                {{ title.Description }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-2 mr-md-3 fit-content">
            <label>Year</label>
            <select v-model="year" class="form-control">
              <option value selected>All</option>
              <option v-for="item in years" :value="item" :key="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-1">
            <button
              type="button"
              class="btn btn-primary form-control"
              style="margin-top: 1.65rem"
              @click="search"
            >
              View
            </button>
          </div>
        </div>
      </form>
      <!-- /Filter -->

      <!-- Table Result -->
      <form id="updateForm" @submit.prevent="update"></form>
      <button class="btn btn-sm btn-link pl-0" @click="showAddModal">
        + Add Leave Entitlement
      </button>
      <div class="table-responsive">
        <table class="table table-bordered" style="width: 100%">
          <thead class="thead-light">
            <tr>
              <th>Leave Type</th>
              <th>Effective From</th>
              <th>Effective Till</th>
              <th>Total Leave Entitlement Days</th>
              <th>Leave Days Taken</th>
              <th>Leave Days Balance</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in leaveEntitlements" :key="item.Id">
              <template v-if="formUpdate.Id !== item.Id">
                <td>{{ item.LeaveTypeDescription }}</td>
                <td>{{ item.EffectiveDateDisplay }}</td>
                <td>{{ item.ExpiryDateDisplay }}</td>
                <td>{{ item.TotalLeaveEntitlementDays }}</td>
                <td>{{ item.LeaveDaysTaken }}</td>
                <td>{{ item.LeaveDaysBalance }}</td>
                <td>
                  <a href="javascript:void(0)" @click.prevent="edit(item)"
                    >Edit</a
                  >
                  <span class="ml-1 mr-1">|</span>
                  <a href="javascript:void(0)" @click.prevent="remove(item.Id)"
                    >Delete</a
                  >
                </td>
              </template>
              <template v-else>
                <td>{{ formUpdate.LeaveTypeDescription }}</td>
                <td class="table-info align-middle">
                  <input
                    form="updateForm"
                    type="date"
                    class="form-control form-control-sm"
                    v-model="formUpdate.EffectiveDateStr"
                    required
                  />
                </td>
                <td class="table-info align-middle">
                  <input
                    form="updateForm"
                    type="date"
                    class="form-control form-control-sm"
                    v-model="formUpdate.ExpiryDateStr"
                    required
                  />
                </td>
                <td class="table-info align-middle">
                  <input
                    form="updateForm"
                    type="number"
                    class="form-control form-control-sm"
                    v-model="formUpdate.TotalLeaveEntitlementDays"
                    min="1"
                    step=".5"
                    required
                  />
                </td>
                <td class="table-info align-middle">
                  {{ item.LeaveDaysTaken }}
                </td>
                <td class="table-info align-middle">
                  {{ item.LeaveDaysBalance }}
                </td>
                <td class="table-info align-middle">
                  <button
                    type="submit"
                    class="btn btn-sm btn-link p-0"
                    form="updateForm"
                  >
                    Update
                  </button>
                  <span class="ml-1 mr-1">|</span>
                  <button
                    type="button"
                    class="btn btn-sm btn-link p-0"
                    @click="cancel"
                  >
                    Cancel
                  </button>
                </td>
              </template>
            </tr>
            <tr v-if="!leaveEntitlements || !leaveEntitlements.length">
              <td colspan="7" style="text-align: center">
                No records available
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="leaveEntitlements.length" class="display-page">
        Displaying
        <select
          class="custom-select custom-select-sm"
          v-model="paging.SizePerPage"
          style="width: 65px; margin-right: 10px; margin-left: 5px"
        >
          <option
            v-for="item in paging.Sizes"
            v-bind:key="item"
            v-bind:value="item"
          >
            {{ item }}
          </option>
        </select>
        <button
          v-if="paging.CurrentPage > 1"
          v-on:click="first()"
          class="btn btn-sm btn-link"
        >
          First
        </button>
        <button
          v-on:click="prev()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage > 1"
        >
          Prev
        </button>
        <span style="padding-left: 5px; padding-right: 5px"
          >{{ paging.CurrentPage }}/{{ paging.TotalPage }}</span
        >
        <button
          v-on:click="next()"
          class="btn btn-sm btn-light"
          v-if="paging.CurrentPage < paging.TotalPage"
        >
          Next
        </button>
        <button
          v-if="paging.CurrentPage < paging.TotalPage"
          v-on:click="last()"
          class="btn btn-sm btn-link"
        >
          Last
        </button>
      </div>
      <!-- /Table Result -->
      <div class="text-center">
        <router-link
          class="btn btn-secondary"
          :to="{ name: 'adminEmployeeIndex' }"
          ><i class="fa fa-reply"></i>&nbsp;Back to Employee List</router-link
        >
      </div>
    </div>
    <!-- Add Leave Entitlement Modal -->
    <modal
      name="addModal"
      width="50%"
      height="auto"
      :scrollable="true"
      :clickToClose="false"
    >
      <div class="text-center my-3">
        <h4>Add Leave Entitlement</h4>
      </div>
      <form @submit.prevent="add" class="page-content mx-2 my-2 pb-0">
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            >Leave Type<span class="text-danger">*</span></label
          >
          <label class="col-form-label">:</label>
          <div class="col-md-6">
            <select
              v-model="formAdd.LeaveTypeDesc"
              class="form-control"
              required
            >
              <option value selected>-- Please Select --</option>
              <option
                v-for="(item, i) in leaveTypesDefault"
                :value="item.Description"
                :key="i"
              >
                {{ item.Description }}
              </option>
              <option value="Others">Others</option>
            </select>
          </div>
        </div>
        <div class="form-group row" v-if="formAdd.LeaveTypeDesc == 'Others'">
          <label class="col-md-4 col-form-label mt-md-3"
            >Title<span class="text-danger">*</span></label
          >
          <label class="col-form-label mt-md-3">:</label>
          <div class="col-md-6 mt-md-2">
            <div class="row">
              <div class="col-md-12">
                <div class="form-check form-check-inline mr-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="titleCreateOption"
                    id="rbNewTitle"
                    value="true"
                    v-model="isTitleNew"
                  />
                  <label class="form-check-label" for="rbNewTitle"
                    >Add new title</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="titleCreateOption"
                    id="rbExistingTitle"
                    value="false"
                    v-model="isTitleNew"
                  />
                  <label class="form-check-label" for="rbExistingTitle"
                    >Get from existing titles</label
                  >
                </div>
              </div>
            </div>
            <div class="row" v-if="formAdd.LeaveTypeDesc == 'Others'">
              <div class="col-md-12">
                <input
                  v-if="isTitleNew == 'true'"
                  class="form-control"
                  type="text"
                  placeholder="Enter new title here"
                  required
                  v-model="formAdd.NewTitle"
                />
                <select
                  v-if="isTitleNew == 'false'"
                  class="form-control"
                  required
                  v-model="formAdd.ExistingTitle"
                >
                  <option value selected>-- Please Select --</option>
                  <option
                    v-for="(title, i) in allLeaveTitles"
                    :value="title.Description"
                    :key="i"
                  >
                    {{ title.Description }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            >Effective From<span class="text-danger">*</span></label
          >
          <label class="col-form-label">:</label>
          <div class="col-md-6">
            <input
              v-model="formAdd.EffectiveDateStr"
              type="date"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            >Effective Till<span class="text-danger">*</span></label
          >
          <label class="col-form-label">:</label>
          <div class="col-md-6">
            <input
              v-model="formAdd.ExpiryDateStr"
              type="date"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            >Total Leave Entitlement Days<span class="text-danger"
              >*</span
            ></label
          >
          <label class="col-form-label">:</label>
          <div class="col-md-6">
            <input
              v-model="formAdd.TotalLeaveEntitlementDays"
              type="number"
              class="form-control"
              min="1"
              step=".5"
              required
            />
          </div>
        </div>
        <hr class="my-0" />
        <div class="form-group row mt-3 mb-0">
          <div class="col-md-12 text-right">
            <button type="submit" class="btn btn-primary mb-3 mr-3">Add</button>
            <button
              type="button"
              class="btn btn-secondary mb-3"
              @click="$modal.hide('addModal')"
            >
              Close
            </button>
          </div>
        </div>
      </form>
    </modal>
    <!-- /Add Leave Entitlement Modal -->
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      employeeId: "",
      employeeName: "",
      employeeEmail: "",
      organisationName: "",
      //for filter
      allLeaveTypes: [],
      leaveTypesDefault: [],
      userLeaveTitles: [],
      years: [],
      leaveTypeDesc: "",
      leaveTitle: "",
      year: "",
      //for table result
      leaveEntitlements: [],
      paging: {
        CurrentPage: 1,
        TotalPage: "",
        SizePerPage: 20,
        Sizes: [20, 50, 100],
      },
      formUpdate: {
        Id: "",
        LeaveTypeDescription: "",
        EffectiveDateStr: "",
        ExpiryDateStr: "",
        TotalLeaveEntitlementDays: null,
      },
      //for add modal
      isTitleNew: "true",
      allLeaveTitles: [],
      formAdd: {
        LeaveTypeDesc: "",
        NewTitle: "",
        ExistingTitle: "",
        EffectiveDateStr: "",
        ExpiryDateStr: "",
        TotalLeaveEntitlementDays: null,
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  watch: {
    "paging.SizePerPage": function () {
      this.paging.CurrentPage = 1;
      this.getLeaveEntitlementList();
    },
  },
  methods: {
    getEmployeeDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getEmployeeDetail(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            let data = response.data.data;
            this.employeeId = data.EmployeeId;
            this.employeeName = data.Name;
            this.employeeEmail = data.Email;
            this.organisationName = data.OrganisationName;
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for filter
    getLeaveTypeListForDropdown() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getLeaveTypeListForDropdown(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          let leaveType = API.constant.leaveType;
          this.allLeaveTypes = response.data.allLeaveTypes;
          this.leaveTypesDefault = response.data.allLeaveTypes.filter(
            (c) =>
              c.Description != leaveType.FullDayAnnual &&
              c.Description != leaveType.HalfDayAnnualMorning &&
              c.Description != leaveType.HalfDayAnnualAfternoon &&
              c.IsCustom == false
          );
          this.userLeaveTitles = response.data.userLeaveTitles;
          this.allLeaveTitles = response.data.allLeaveTypes.filter(
            (c) => c.IsCustom == true
          );
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    getLeaveEntitlementYearsForDropdown() {
      let param = {
        Token: this.token,
      };
      API.common
        .getLeaveEntitlementYearsForDropdown(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }
          this.years = response.data.data;
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for table result
    search() {
      this.paging.CurrentPage = 1;
      this.getLeaveEntitlementList();
    },
    getLeaveEntitlementList() {
      let leaveType = this.allLeaveTypes.find(
        (c) =>
          c.Description ==
          (this.leaveTypeDesc == "Others"
            ? this.leaveTitle
            : this.leaveTypeDesc)
      );
      let param = {
        Token: this.token,
        UserId: this.$route.params.id,
        LeaveTypeId: leaveType ? leaveType.Id : "",
        IsOthersSelected: this.leaveTypeDesc == "Others",
        Year: this.year,
        CurrentPage: this.paging.CurrentPage,
        SizePerPage: this.paging.SizePerPage,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getLeaveEntitlementList(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.leaveEntitlements = response.data.data;
            this.paging.TotalPage = response.data.pageCount;
          } else {
            this.$swal(response.data.message, {
              icon: "error",
            });
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
    //for action button
    cancel() {
      this.formUpdate = {
        Id: "",
        LeaveTypeDescription: "",
        EffectiveDateStr: "",
        TotalLeaveEntitlementDays: null,
      };
      this.getLeaveEntitlementList();
    },
    edit(payload) {
      this.cancel();
      this.formUpdate.Id = payload.Id;
      this.formUpdate.LeaveTypeDescription = payload.LeaveTypeDescription;
      this.formUpdate.EffectiveDateStr = payload.EffectiveDateCal;
      this.formUpdate.ExpiryDateStr = payload.ExpiryDateCal;
      this.formUpdate.TotalLeaveEntitlementDays =
        payload.TotalLeaveEntitlementDays;
    },
    remove(Id) {
      let param = {
        Token: this.token,
        Id: Id,
        UserId: this.$route.params.id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete the leave entitlement?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.$store.state.isLoading = true;
          API.admin
            .deleteLeaveEntitlement(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.cancel();
                this.getLeaveTypeListForDropdown();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    update() {
      let param = {
        Token: this.token,
        UserId: this.$route.params.id,
        Id: this.formUpdate.Id,
        EffectiveDateStr: this.formUpdate.EffectiveDateStr,
        ExpiryDateStr: this.formUpdate.ExpiryDateStr,
        TotalLeaveEntitlementDays: this.formUpdate.TotalLeaveEntitlementDays,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to update the leave entitlement?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willUpdate) => {
        if (willUpdate) {
          this.$store.state.isLoading = true;
          API.admin
            .updateLeaveEntitlement(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.cancel();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    //for pagination
    first() {
      this.paging.CurrentPage = 1;
      this.getLeaveEntitlementList();
    },
    last() {
      this.paging.CurrentPage = this.paging.TotalPage;
      this.getLeaveEntitlementList();
    },
    next() {
      this.paging.CurrentPage += 1;
      this.getLeaveEntitlementList();
    },
    prev() {
      this.paging.CurrentPage -= 1;
      this.getLeaveEntitlementList();
    },
    //for modal
    showAddModal() {
      this.isTitleNew = "true";
      this.formAdd = {
        LeaveTypeDesc: "",
        NewTitle: "",
        ExistingTitle: "",
        EffectiveDateStr: "",
        TotalLeaveEntitlementDays: null,
      };
      this.$modal.show("addModal");
    },
    add() {
      //check if new title already exists
      if (this.isTitleNew == "true") {
        let rec = this.allLeaveTitles.find(
          (c) =>
            c.Description.trim().toLowerCase() ==
            this.formAdd.NewTitle.trim().toLowerCase()
        );
        if (rec) {
          this.$swal(
            "Title already exists. Please select from the existing titles.",
            { icon: "error" }
          );
          return;
        }
      }
      //call API
      let leaveTypeDescription =
        this.formAdd.LeaveTypeDesc == "Others"
          ? this.isTitleNew == "true"
            ? this.formAdd.NewTitle
            : this.formAdd.ExistingTitle
          : this.formAdd.LeaveTypeDesc;
      let leaveType = this.allLeaveTypes.find(
        (c) => c.Description == leaveTypeDescription
      );
      let param = {
        Token: this.token,
        UserId: this.$route.params.id,
        LeaveTypeId: leaveType ? leaveType.Id : "",
        LeaveTypeDescription: leaveTypeDescription,
        EffectiveDateStr: this.formAdd.EffectiveDateStr,
        ExpiryDateStr: this.formAdd.ExpiryDateStr,
        TotalLeaveEntitlementDays: this.formAdd.TotalLeaveEntitlementDays,
      };
      //console.log("param in add", param);
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to add the new leave entitlement?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willAdd) => {
        if (willAdd) {
          this.$store.state.isLoading = true;
          API.admin
            .addLeaveEntitlement(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "adminLogin" });
                return;
              }

              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.cancel();
                this.getLeaveTypeListForDropdown();
                this.$modal.hide("addModal");
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
  },
  created() {
    this.getLeaveTypeListForDropdown();
    this.getEmployeeDetail();
    this.getLeaveEntitlementList();
    this.getLeaveEntitlementYearsForDropdown();
  },
};
</script>

<style scoped>
.col-form-label {
  max-width: 205px;
}

.fit-content {
  max-width: fit-content;
}
@media only screen and (max-width: 768px) {
  /* for mobile phone */
  .fit-content {
    max-width: none;
  }
}
</style>