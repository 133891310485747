<template>
  <div class="page-content-emp">
    <h4>My Account</h4>
    <hr class="my-0" />
    <div class="row mt-3">
      <div class="col-6">
        <h6><b>Name</b></h6>
        {{ name }}
      </div>
      <div class="col-6">
        <h6><b>Email</b></h6>
        {{ email }}
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6">
        <h6><b>Company</b></h6>
        {{ company }}
      </div>
      <div class="col-6">
        <h6><b>Company Join Date</b></h6>
        {{ companyJoinDate }}
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6">
        <h6><b>Leave Entitlement Type</b></h6>
        {{ leaveEntitlementType }}
      </div>
      <div class="col-6">
        <h6><b>Length of Service (Years)</b></h6>
        {{ lengthOfService }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      company: "",
      companyJoinDate: "",
      leaveEntitlementType: "",
      lengthOfService: "",
    };
  },
  created() {
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    this.name = data.Name;
    this.email = data.Email;
    this.company = data.OrganisationName;
    this.companyJoinDate = data.CompanyJoinDateFormStr;
    this.leaveEntitlementType = data.LeaveEntitlementType;
    this.lengthOfService = data.ServiceLength;
  },
};
</script>