<template>
  <div style="margin: 20px">
    <h4>View Employee</h4>
    <div class="page-content mt-4">
      <!-- DETAILS -->
      <h5>Details</h5>
      <hr class="my-0" />

      <div class="row mt-2">
        <div class="col-md-2 font-weight-bold detail-left-title">
          Organisation:
        </div>
        <div class="col-md-3">{{ emp.OrganisationName }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">Status:</div>
        <div class="col-md-3">{{ emp.Active ? "Active" : "Inactive" }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold detail-left-title">ID:</div>
        <div class="col-md-3">{{ emp.EmployeeId }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">
          Date of Birth:
        </div>
        <div class="col-md-3">{{ emp.BirthDateStr }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold detail-left-title">Name:</div>
        <div class="col-md-3">{{ emp.Name }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">Gender:</div>
        <div class="col-md-3">{{ emp.Gender }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold detail-left-title">
          NRIC Number:
        </div>
        <div class="col-md-3">{{ emp.NRIC }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">
          Nationality:
        </div>
        <div class="col-md-3">{{ emp.Nationality }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold detail-left-title">
          Place of Birth:
        </div>
        <div class="col-md-3">{{ emp.PlaceOfBirth }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">
          PR Issuance Date:
        </div>
        <div class="col-md-3">{{ emp.PR_IssuanceDateStr }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold detail-left-title">
          Citizenship:
        </div>
        <div class="col-md-3">{{ emp.CitizenshipName }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">
          Religion:
        </div>
        <div class="col-md-3">{{ emp.Religion }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold detail-left-title">
          Marital Status:
        </div>
        <div class="col-md-3">{{ emp.MaritalStatus ? "Yes" : "No" }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold detail-right-title">
          Number of Children:
        </div>
        <div class="col-md-3">{{ emp.NumberOfChildren }}</div>
      </div>
      <!-- /DETAILS -->

      <!-- CONTACT INFORMATION -->
      <h5 class="mt-4">Contact Information</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <div class="col-md-1 font-weight-bold">Email:</div>
        <div class="col-md-3">{{ emp.Email }}</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 font-weight-bold contact-right-title">
          Contact Number:
        </div>
        <div class="col-md-3">{{ emp.ContactNumber }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-1 font-weight-bold">Address:</div>
        <div class="col-md-5">
          <div v-for="(item, i) in emp.Addresses" :key="i" class="mb-2">
            <label class="font-weight-bold mb-0">Address {{ i + 1 }}</label
            ><br />
            {{ item.Address }}
          </div>
        </div>
      </div>
      <!-- /CONTACT INFORMATION -->

      <!-- EMPLOYMENT INFORMATION -->
      <h5 class="mt-4">Employment Information</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <div class="col-md-2 font-weight-bold employment-left-title">
          Company Join Date:
        </div>
        <div class="col-md-3">{{ emp.CompanyJoinDateStr }}</div>
        <div class="col-md-2 employment-middle-space"></div>
        <div class="col-md-2 font-weight-bold employment-right-title">
          Probational Period:
        </div>
        <div class="col-md-3">{{ emp.ProbationalPeriod }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold employment-left-title">
          Department:
        </div>
        <div class="col-md-3">{{ emp.Department }}</div>
        <div class="col-md-2 employment-middle-space"></div>
        <div class="col-md-2 font-weight-bold employment-right-title">
          Occupation:
        </div>
        <div class="col-md-3">{{ emp.Occupation }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold employment-left-title">
          Confirmation Date:
        </div>
        <div class="col-md-3">{{ emp.ConfirmationDateStr }}</div>
        <div class="col-md-2 employment-middle-space"></div>
        <div class="col-md-2 font-weight-bold employment-right-title">
          Resignation Date:
        </div>
        <div class="col-md-3">{{ emp.ResignationDateStr }}</div>
      </div>
      <!-- /EMPLOYMENT INFORMATION -->

      <!-- PAYROLL INFORMATION -->
      <h5 class="mt-4">Payroll Information</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <div class="col-md-2 font-weight-bold payroll-left-title">
          Basic Salary:
        </div>
        <div class="col-md-4">{{ emp.BasicSalary }}</div>
        <div class="col-md-1 payroll-middle-space"></div>
        <div class="col-md-2 font-weight-bold payroll-right-title">
          Salary Effective Date:
        </div>
        <div class="col-md-4">{{ emp.SalaryEffectiveDateStr }}</div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2 font-weight-bold payroll-left-title">
          Employee CPF (%):
        </div>
        <div class="col-md-4">{{ emp.EmployeeCpf }}</div>
        <div class="col-md-1 payroll-middle-space"></div>
        <div class="col-md-2 font-weight-bold payroll-right-title">
          Employer CPF (%):
        </div>
        <div class="col-md-4">{{ emp.EmployerCpf }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold payroll-left-title">
          Fund Contribution Type<br />
          (CDAC, MOSQ, SINDA, EURA):
        </div>
        <div class="col-md-4">{{ emp.FundContributionType }}</div>
        <div class="col-md-1 payroll-middle-space"></div>
        <div class="col-md-2 font-weight-bold payroll-right-title">
          Foreign Worker's Levy:
        </div>
        <div class="col-md-4">{{ emp.ForeignWorkerLevy }}</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold payroll-left-title">
          Bank Details:
        </div>
        <div class="col-md-4">
          <div v-for="(item, i) in emp.Banks" :key="i">
            <label class="font-weight-bold mb-0"
              >Bank Account {{ i + 1 }}</label
            >
            <table class="table table-bordered">
              <tr>
                <th>Bank Name {{ i + 1 }}</th>
                <th>Bank Account Number {{ i + 1 }}</th>
              </tr>
              <tr>
                <td>{{ item.Name }}</td>
                <td>{{ item.AccountNo }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-md-1 payroll-middle-space"></div>
        <div class="col-md-2 font-weight-bold payroll-right-title">
          Allowance:
        </div>
        <div class="col-md-4">
          <div v-for="(item, i) in emp.Allowances" :key="i">
            <label class="font-weight-bold mb-0">Allowance {{ i + 1 }}</label>
            <table class="table table-bordered">
              <tr>
                <th>Allowance Name {{ i + 1 }}</th>
                <th>Allowance Amount {{ i + 1 }}</th>
              </tr>
              <tr>
                <td>{{ item.Name }}</td>
                <td>{{ item.Amount }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold payroll-left-title">
          Deduction:
        </div>
        <div class="col-md-4">
          <div v-for="(item, i) in emp.Deductions" :key="i">
            <label class="font-weight-bold mb-0">Deduction {{ i + 1 }}</label>
            <table class="table table-bordered">
              <tr>
                <th>Deduction Name {{ i + 1 }}</th>
                <th>Deduction Amount {{ i + 1 }}</th>
              </tr>
              <tr>
                <td>{{ item.Name }}</td>
                <td>{{ item.Amount }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-md-1 payroll-middle-space"></div>
      </div>
      <!-- /PAYROLL INFORMATION -->

      <!-- LEAVE DETAILS -->
      <h5 class="mt-4">Leave Details</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <div class="col-md-2 font-weight-bold leave-left-title">
          Type of Leave Entitlement Organisation:
        </div>
        <div class="col-md-3">{{ emp.LeaveEntitlementType }}</div>
        <div class="col-md-2 leave-middle-space"></div>
        <div
          class="col-md-2 font-weight-bold leave-right-title leave-right-title"
        >
          Length of Service (Years):
        </div>
        <div class="col-md-3">{{ emp.ServiceLength }}</div>
      </div>

      <div class="row mt-3">
        <div class="col-md-2 font-weight-bold leave-left-title">Remarks:</div>
        <div class="col-md-3">{{ emp.Remarks }}</div>
      </div>
      <!-- /LEAVE DETAILS -->

      <div class="row mt-4">
        <div class="text-center col-md-12">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'adminEmployeeIndex' }"
            ><i class="fa fa-reply mr-2"></i>Back to Employee List</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      emp: {
        OrganisationName: "",
        Active: null,
        EmployeeId: "",
        Password: "",
        Name: "",
        BirthDateStr: "",
        Email: "",
        ContactNumber: "",
        NRIC: "",
        Department: "",
        CompanyJoinDateStr: "",
        MaritalStatus: null,
        NumberOfChildren: "",
        LeaveEntitlementType: "",
        ServiceLength: "",

        Gender: "",
        PlaceOfBirth: "",
        Nationality: "",
        CitizenshipName: "",
        PR_IssuanceDateStr: "",
        Religion: "",
        ProbationalPeriod: "",
        Occupation: "",
        ConfirmationDateStr: "",
        ResignationDateStr: "",
        BasicSalary: "",
        EmployeeCpf: "",
        EmployerCpf: "",
        SalaryEffectiveDateStr: "",
        FundContributionType: "",
        ForeignWorkerLevy: "",
        Remarks: "",
        Addresses: [],
        Banks: [],
        Allowances: [],
        Deductions: [],
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  methods: {
    getEmployeeDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getEmployeeDetail(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.emp = response.data.data;
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.getEmployeeDetail();
  },
};
</script>

<style scoped>
.detail-left-title {
  max-width: 120px;
}
.detail-right-title,
.contact-right-title {
  max-width: 160px;
}

.employment-left-title {
  max-width: 160px;
}
.employment-middle-space {
  max-width: 198px;
}
.employment-right-title {
  max-width: 160px;
}

.payroll-left-title {
  max-width: 220px;
}
.payroll-middle-space {
  max-width: 30px;
}
.payroll-right-title {
  max-width: 180px;
}

.leave-left-title {
  max-width: 200px;
}
.leave-middle-space {
  max-width: 157px;
}
.leave-right-title {
  max-width: 200px;
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .detail-left-title {
    max-width: none;
  }
  .detail-right-title,
  .contact-right-title {
    max-width: none;
  }

  .employment-left-title {
    max-width: none;
  }
  .employment-middle-space {
    max-width: none;
  }
  .employment-right-title {
    max-width: none;
  }

  .payroll-left-title {
    max-width: none;
  }
  .payroll-middle-space {
    max-width: none;
  }
  .payroll-right-title {
    max-width: none;
  }

  .leave-left-title {
    max-width: none;
  }
  .leave-middle-space {
    max-width: none;
  }
  .leave-right-title {
    max-width: none;
  }
}
</style>