<template>
  <div class="page-content-emp mb-3">
    <h4>Leave Application</h4>
    <hr class="my-0" />
    <form class="mt-3" @submit.prevent>
      <div class="form-group">
        <label>Status</label>
        <input disabled type="text" class="form-control" v-model="status" />
      </div>
      <div class="form-group">
        <label>Name</label>
        <input disabled type="text" class="form-control" v-model="name" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input disabled type="text" class="form-control" v-model="email" />
      </div>
      <div class="form-group">
        <label>Leave Type</label>
        <input
          disabled
          type="text"
          class="form-control"
          v-model="leaveTypeDescription"
        />
      </div>
      <div class="form-group" v-if="leaveTypeDescription == 'Medical Leave'">
        <label class="mb-1">Medical Certificate</label>
        <download-mc-button
          :mcFileName="mcFileName"
          :leaveApplicationId="$route.params.id"
        ></download-mc-button>
      </div>
      <div class="form-group">
        <label>Remarks</label>
        <textarea v-model="remarks" class="form-control" disabled />
      </div>
      <div class="form-row">
        <div class="form-group col-md-5">
          <label>Start Date</label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="startDate"
          />
        </div>
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-5">
          <label>End Date</label>
          <input disabled type="text" class="form-control" v-model="endDate" />
        </div>
      </div>
      <div class="form-group">
        <label>Number of Leave Days</label>
        <input
          disabled
          type="text"
          class="form-control"
          v-model="noOfLeaveDays"
        />
      </div>
      <input type="checkbox" id="chkApprovalObtained" checked disabled />&nbsp;
      <label for="chkApprovalObtained">
        I have obtained APPROVAL from my boss/manager/supervisor</label
      >
      <div
        class="text-center"
        v-if="status == 'Pending Approval' && isApprover"
      >
        <button class="btn btn-primary mr-3" @click="approve">Approve</button>
        <button class="btn btn-danger" @click="reject">Reject</button>
      </div>
    </form>
  </div>
</template>

<script>
import API from "../../../http-common.js";
import DownloadMcButton from "../../Shared/DownloadMedicalCertificateButton.vue";
export default {
  components: {
    DownloadMcButton,
  },
  data() {
    return {
      name: "",
      email: "",
      leaveTypeDescription: "",
      remarks: "",
      startDate: "",
      endDate: "",
      noOfLeaveDays: "",
      status: "",
      isApprover: false,
      mcFileName: "",
    };
  },
  computed: {
    token() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Token;
    },
    myEmail() {
      let data = JSON.parse(localStorage.getItem("employeelmstoken"));
      if (!data) return null;
      return data.Email;
    },
  },
  methods: {
    downloadMcFile() {},
    approve() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to approve the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willApprove) => {
        if (willApprove) {
          this.$store.state.isLoading = true;
          API.employee
            .approveLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "login" });
                return;
              }
              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.getLeaveApplicationDetail();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    reject() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to reject the leave application?",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willReject) => {
        if (willReject) {
          this.$store.state.isLoading = true;
          API.employee
            .rejectLeaveApplication(param)
            .then((response) => {
              this.$store.state.isLoading = false;
              if (response.data.message == "Unauthorized access") {
                this.$swal(
                  "Multiple login detected. You have been logged out",
                  { icon: "error" }
                );
                this.$router.replace({ name: "login" });
                return;
              }
              this.$swal(response.data.message, {
                icon: response.data.success ? "success" : "error",
              });
              if (response.data.success) {
                this.getLeaveApplicationDetail();
              }
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              this.$swal(error.response.data.Message, { icon: "error" });
            });
        }
      });
    },
    getLeaveApplicationDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      API.admin
        .getLeaveApplicationDetail(param)
        .then((response) => {
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "login" });
            return;
          }

          if (response.data.success) {
            let data = response.data.data;
            if (
              data.ApproverEmail.trim().toLowerCase() ==
              this.myEmail.trim().toLowerCase()
            ) {
              this.isApprover = true;
            }
            this.name = data.EmployeeName;
            this.email = data.EmployeeEmail;
            this.leaveTypeDescription = data.LeaveTypeDescription;
            this.remarks = data.Remarks;
            this.startDate = data.StartDateDisplayEmp;
            this.endDate = data.EndDateDisplayEmp;
            this.noOfLeaveDays = data.NumberOfLeaveDays;
            this.status = data.Status;
            this.mcFileName = data.MedicalCertificateFileName;
          } else {
            this.$swal(response.data.message, { icon: "error" });
          }
        })
        .catch((error) => {
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },

  created() {
    //if no token,redirect to login
    let data = JSON.parse(localStorage.getItem("employeelmstoken"));
    if (!data) {
      this.$router.replace({ name: "login" });
      return;
    }
    this.getLeaveApplicationDetail();
  },
};
</script>