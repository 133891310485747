<template>
  <div>
    <h4>View Organisation</h4>
    <div class="mt-4 mb-5 page-content">
      <h5>Details</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">Logo 1</label>
        <label class="font-weight-bold">:</label>
        <div class="col-3">
          <img
            v-if="org.Logo1"
            :src="org.Logo1"
            class="ml-1"
            style="width: 40%"
          />
        </div>
        <div class="col-1"></div>

        <label class="show-title font-weight-bold col-2">Logo 2</label>
        <label class="font-weight-bold">:</label>
        <div class="col-3">
          <img
            v-if="org.Logo2"
            :src="org.Logo2"
            class="ml-1"
            style="width: 40%"
          />
        </div>
      </div>
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">Code</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Code }}</label>
        <div class="col-1"></div>
        <label class="show-title font-weight-bold col-2">Name</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Name }}</label>
      </div>
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">Contact Person</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.ContactPerson }}</label>
        <div class="col-1"></div>
        <label class="show-title font-weight-bold col-2">Contact Number</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.ContactNumber }}</label>
      </div>

      <h5 class="mt-4">Address</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">Address 1</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Address1 }}</label>
        <div class="col-1"></div>
        <label class="show-title font-weight-bold col-2">Address 2</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Address2 }}</label>
      </div>
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">Address 3</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Address3 }}</label>
      </div>

      <h5 class="mt-4">Government Related</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">UEN Account 1</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.UEN1 }}</label>
        <div class="col-1"></div>
        <label class="show-title font-weight-bold col-2">UEN Account 2</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.UEN2 }}</label>
      </div>

      <h5 class="mt-4">Leave</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <label class="col-3 font-weight-bold"
          >Leave Entitlement Organisation</label
        >
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.LeaveEntitlementType }}</label>
      </div>

      <h5 class="mt-4">Others</h5>
      <hr class="my-0" />
      <div class="row mt-2">
        <label class="show-title font-weight-bold col-2">Status</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Active ? "Active" : "Inactive" }}</label>
        <div class="col-1"></div>
        <label class="show-title font-weight-bold col-2">Remarks</label>
        <label class="font-weight-bold">:</label>
        <label class="col-3">{{ org.Remarks }}</label>
      </div>
      <div class="row mt-4">
        <div class="text-center col-12">
          <router-link
            class="btn btn-sm btn-primary"
            :to="{ name: 'adminOrganisationIndex' }"
            ><i class="fa fa-reply"></i>&nbsp;Back to Organisation
            List</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../http-common.js";
export default {
  data() {
    return {
      org: {
        Logo1: null,
        Logo2: null,
        Code: "",
        ContactPerson: "",
        ContactNumber: "",
        Address1: "",
        Address2: "",
        Address3: "",
        UEN1: "",
        UEN2: "",
        LeaveEntitlementType: "",
        Active: null,
        Remarks: "",
      },
    };
  },
  computed: {
    token() {
      return localStorage.getItem("adminlmstoken");
    },
  },
  methods: {
    getOrganisationDetail() {
      let param = {
        Token: this.token,
        Id: this.$route.params.id,
      };
      this.$store.state.isLoading = true;
      API.admin
        .getOrganisationDetail(param)
        .then((response) => {
          this.$store.state.isLoading = false;
          if (response.data.message == "Unauthorized access") {
            this.$swal("Multiple login detected. You have been logged out", {
              icon: "error",
            });
            this.$router.replace({ name: "adminLogin" });
            return;
          }

          if (response.data.success) {
            this.org = response.data.data;
          }
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          this.$swal(error.response.data.Message, { icon: "error" });
        });
    },
  },
  created() {
    this.getOrganisationDetail();
  },
};
</script>